import { useEffect } from 'react'

function setDocumentTitle(title?: string, runLast?: boolean) {
	const suffix = 'Appital'
	const fullTitle = title ? `${title} - ${suffix}` : suffix
	if (runLast) {
		window.requestAnimationFrame(() => (document.title = fullTitle))
		return
	}
	document.title = fullTitle
}

export default function useDocumentTitle(title?: string, runLast?: boolean) {
	useEffect(() => {
		setDocumentTitle(title, runLast)
	}, [title, runLast])
	return setDocumentTitle
}
