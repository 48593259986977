import { CombinedError } from 'urql'

export const genericErrorMessage =
	'Something went wrong. If the issue persists, contact support@appital.io'

export const unauthorisedErrorMessage = 'Not authorised to perform this action.'

/**
 * Error to be used when there is no specific feedback to be given.
 *
 * @example
 * throw new GenericError()
 */
export class GenericError extends Error {
	constructor() {
		super(genericErrorMessage)
		this.name = 'GenericError'
	}
}

/**
 * Error to be used when access to a resource is not authorised
 *
 * @example
 * throw new UnauthorisedError()
 */
export class UnauthorisedError extends Error {
	constructor() {
		super(unauthorisedErrorMessage)
		this.name = 'UnauthorisedError'
	}
}

/**
 * Error to be used when validating data client-side so we can differentiate it.
 *
 * @example
 * throw new ValidationError()
 */
export class ValidationError extends Error {
	constructor(msg: string) {
		super(msg)
		this.name = 'ValidationError'
	}
}

/**
 * Utility for converting any potential error to a string. If handles unknown
 * errors too and checks if the message is in a list of known unfriendly
 * messages and converts to a generic one.
 *
 * @example
 * errorToString(err)
 */
export function errorToString(error: unknown): string {
	if (error === undefined || error === null) return ''
	if (!(error instanceof Error)) return genericErrorMessage

	const msg =
		error instanceof CombinedError
			? error.graphQLErrors.map((e) => e.message).join(', ')
			: error.message

	// make some unfriendly errors generic
	if (
		!msg ||
		msg.toLowerCase().includes('failed to fetch') ||
		msg.toLowerCase().includes('unexpected end of json input') ||
		msg.toLowerCase().includes('internal system error') ||
		msg.toLowerCase().includes('something went wrong')
	) {
		return genericErrorMessage
	}

	return msg
}
