import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import { Link } from 'react-router-dom'

type Props = ComponentProps<'div'>

export const Logo: FC<Props> = ({ className, ...props }) => (
	<div
		{...props}
		className={clsx('h-4 min-w-[1rem] lg:h-[1.1rem] xl:h-5', className)}
	>
		<Link
			to="/"
			title="Appital"
			className="inline-flex h-full w-[100px] max-w-full overflow-hidden @container"
		>
			<h1 className="sr-only">Appital</h1>

			<svg
				preserveAspectRatio="xMinYMin"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 787 160"
				className="h-full w-[100px] flex-none"
			>
				{/* Symbol */}
				<g>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0 132H40V92H0V132Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M80 52H0V32H80H100V52V132H80V52Z"
						fill="#F3B11F"
					/>
				</g>

				{/* Appital */}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M178.887 32L140 132H162.046L171.102 106.542H208.325L217.378 132H240L201.116 32H178.887ZM189.831 54.7309L202.457 90.1813H176.845L189.831 54.7309ZM280.187 50.1818H303.002C313.508 50.1818 319.063 55.4248 319.063 64.8C319.063 74.0813 313.508 79.2713 303.002 79.2713H280.187V50.1818ZM303.147 32H260V132H280.186V97.4531H303.147C325.879 97.4531 340 84.7528 340 64.8C340 44.7581 325.879 32 303.147 32ZM403.078 79.2737C413.563 79.2737 419.106 74.0813 419.106 64.8C419.106 55.4248 413.563 50.1818 403.078 50.1818H380.145V79.2737H403.078ZM360 32H403.221C425.909 32 440 44.7581 440 64.8C440 84.7528 425.909 97.4531 403.221 97.4531H380.144V132H360V32ZM462.5 132H482.5V32H462.5V132ZM536.833 50.1818H507.5V32H590V50.1818H558.833V132H536.833V50.1818ZM657.457 90.1813H631.846L644.833 54.7333L657.457 90.1813ZM633.887 32L595 132H617.048L626.102 106.544H663.325L672.38 132H695L656.116 32H633.887ZM737.245 32V111.999H787.5V132H717.5V32H737.245Z"
					fill="white"
					className="opacity-0 transition-opacity @[6.25rem]:opacity-100"
				/>
			</svg>
		</Link>
	</div>
)
