import { ROLES as ADMIN_ROLES } from 'admin/roles'
import { useLogout } from 'auth/Logout'
import { ROLES as BOOKBUILDS_ROLES } from 'bookbuilds/roles'
import clsx from 'clsx'
import Button from 'core/components/Button'
import { LogOut } from 'core/components/Icon'
import { Logo } from 'core/components/Logo'
import { useUser } from 'core/hooks/useUser'
import { useUserRoles } from 'core/hooks/useUserRoles'
import { ROLES as INTERESTS_ROLES } from 'interests/roles'
import NotificationsList from 'notifications/NotificationsList'
import { ROLES as OPPS_ROLES } from 'opportunities/roles'
import { ROLES as ORDERS_ROLES } from 'orders/roles'
import { memo } from 'react'
import { NavLink } from 'react-router-dom'

export const navHeight = 54

type NavItem = {
	path: string
	label: string
	enabled: boolean
}

/**
 * The application main navigation.
 *
 * @example
 * <Nav />
 */
const Nav = () => {
	const logout = useLogout()
	const roles = useUserRoles()
	const user = useUser()
	const userName = user.name || 'Account'

	const navItems: NavItem[] = [
		{
			path: '/opportunities',
			label: 'Opportunities',
			enabled: roles.some((r) => OPPS_ROLES.includes(r)),
		},
		{
			path: '/orders',
			label: 'Orders',
			enabled: roles.some((r) => ORDERS_ROLES.includes(r)),
		},
		{
			path: '/bookbuilds',
			label: 'BookBuilds',
			enabled: roles.some((r) => BOOKBUILDS_ROLES.includes(r)),
		},
		{
			path: '/interests',
			label: 'Interests',
			enabled: roles.some((r) => INTERESTS_ROLES.includes(r)),
		},
		{
			path: '/admin',
			label: 'Admin',
			enabled: roles.some((r) => ADMIN_ROLES.includes(r)),
		},
	].filter((i) => i.enabled)

	return (
		<header
			className="sticky top-0 z-10 flex flex-wrap content-center items-center gap-x-3 gap-y-0 whitespace-nowrap bg-blue-darkest bg-opacity-50 px-6 text-xs backdrop-blur-md xs:flex-nowrap xs:gap-x-5 md:px-8 xl:text-sm"
			style={{ height: navHeight }}
		>
			<Logo className="mr-auto basis-28 lg:flex-grow lg:basis-0" />

			<nav className="order-3 flex basis-full items-center justify-between gap-2 xs:order-none xs:basis-auto xs:gap-5 md:gap-8 lg:justify-center">
				{navItems.map((navItem) => (
					<NavLink
						key={navItem.path}
						to={navItem.path}
						title={`Go to ${navItem.label}`}
						className={({ isActive }) =>
							clsx(
								'font-bold hover:text-white',
								isActive ? 'selected text-white' : 'text-gray'
							)
						}
					>
						{navItem.label}
					</NavLink>
				))}
			</nav>

			<div className="ml-auto flex flex-initial items-center gap-3 text-xs text-gray-dark md:text-gray lg:flex-grow lg:basis-0 lg:justify-end lg:gap-5">
				<NavLink
					to="/users/me"
					title={userName}
					className={({ isActive }) =>
						clsx(
							'text-right text-2xs font-bold no-underline transition-colors hover:text-white',
							isActive ? 'text-white' : 'text-gray'
						)
					}
				>
					{userName}
				</NavLink>

				{!!roles.length && <NotificationsList />}

				<Button
					title="Sign out"
					variant="bare"
					color="gray-dark"
					className="!h-auto"
					onClick={() => logout()}
				>
					<span className="sr-only">Sign out</span>
					<LogOut width={18} className="not-sr-only" />
				</Button>
			</div>
		</header>
	)
}

export default memo(Nav)
