import clsx from 'clsx'
import Caption from 'core/components/Caption'
import { ComponentProps, forwardRef, ReactNode, useId } from 'react'

type Props = JSX.IntrinsicElements['div'] & {
	color?: (typeof colors)[number]
	label?: ReactNode
	labelProps?: Omit<ComponentProps<typeof Caption>, 'id'>
}

export const colors = [
	'yellow',
	'magenta',
	'gray',
	'gray-dark',
	'blue',
	'green',
	'blue',
] as const

/**
 * Displays text with a compact label above in very close proximity.
 *
 * @example
 * <TextLabel label="Name">Sam Mulube</TextLabel>
 */
const TextLabel = forwardRef<HTMLDivElement, Props>(function TextLabel(
	{ color = 'yellow', label, labelProps, children, ...props },
	ref
) {
	const id = useId()
	return (
		<div {...props} ref={ref}>
			<Caption
				{...labelProps}
				id={id}
				className={clsx(
					color === 'yellow' && 'text-yellow',
					color === 'blue' && 'text-blue',
					color === 'green' && 'text-green',
					color === 'magenta' && 'text-magenta',
					color === 'gray' && 'text-gray',
					color === 'gray-dark' && 'text-gray-dark',
					labelProps?.className
				)}
			>
				{label}
			</Caption>
			<span hidden>: </span>
			<div aria-labelledby={id} data-testid="textlabel-children">
				{children}
			</div>
		</div>
	)
})

export default TextLabel
