import clsx from 'clsx'
import { ComponentProps, forwardRef } from 'react'

type Props = ComponentProps<'span'> & {
	highlighted?: boolean
}

const Badge = forwardRef<HTMLSpanElement, Props>(function Badge(
	{ highlighted = false, children, className, ...props }: Props,
	ref
) {
	return (
		<span
			{...props}
			ref={ref}
			className={clsx(
				'flex h-[22px] w-fit items-center justify-center rounded px-1.5 text-xs font-bold uppercase leading-tight text-gray-light',
				highlighted ? 'bg-[#17428E] bg-opacity-80' : 'bg-blue-darkish',
				className
			)}
		>
			{children}
		</span>
	)
})

export default Badge
