import Badge from 'core/components/Badge'
import { TimeLeft } from 'core/hooks/useTimeLeft'
import { formatDate } from 'core/utils/date'
import { ComponentProps } from 'react'

type Props = Partial<ComponentProps<'div'>> & {
	duration: TimeLeft
	expirationTime: string
}

export default function DistributionEndTime({
	duration,
	expirationTime,
}: Props) {
	return (
		<Badge>
			<span className="flex flex-nowrap gap-1 after:pl-1 after:pr-2 after:opacity-30 after:content-['|']">
				{!!duration.days && (
					<p className="whitespace-nowrap text-gray-light">
						{duration.days}{' '}
						<span className="text-3xs text-gray">d</span>
					</p>
				)}
				{!!duration.hours && (
					<p className="whitespace-nowrap text-gray-light">
						{duration.hours}{' '}
						<span className="text-3xs text-gray">hr</span>
					</p>
				)}
				<p className="whitespace-nowrap text-gray-light">
					{duration.minutes}{' '}
					<span className="text-3xs text-gray">m</span>
				</p>
			</span>

			<p className="whitespace-nowrap text-gray-light">
				{!!duration.isLastDay
					? formatDate(expirationTime, 'timeshort')
					: formatDate(expirationTime, 'datetime')}{' '}
				<span className="uppercase text-gray">End Time</span>
			</p>
		</Badge>
	)
}
