import { usePolling } from 'core/hooks/usePolling'
import { useNotificationsQuery } from 'graphql/generated'
import {
	Context,
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useMemo,
	useState,
} from 'react'

type ContextState = {
	response: ReturnType<typeof useNotificationsQuery>
	limit: number
	setLimit: Dispatch<SetStateAction<number>>
}

const NotificationsContext: Context<ContextState> = createContext(
	{} as ContextState
)

export function useNotificationsContext(): ContextState {
	return useContext(NotificationsContext)
}

export function NotificationsProvider({ children }: { children: ReactNode }) {
	const [limit, setLimit] = useState(30)

	const response = useNotificationsQuery({
		requestPolicy: 'network-only',
		variables: { limit },
	})

	usePolling({
		interval: 5000,
		pause: response[0].fetching,
		refetch: response[1],
	})

	const contextState = useMemo(
		() =>
			({
				response,
				limit,
				setLimit,
			}) satisfies ContextState,
		[limit, response]
	)

	return (
		<NotificationsContext.Provider value={contextState}>
			{children}
		</NotificationsContext.Provider>
	)
}
