import clsx from 'clsx'
import { ChevronDown } from 'core/components/Icon'
import { useSelect, UseSelectProps, UseSelectStateChange } from 'downshift'
import { motion } from 'framer-motion'
import { ComponentProps, CSSProperties, forwardRef, ReactNode } from 'react'

type Props = Partial<UseSelectProps<SelectItem>> & {
	items: SelectItems
	width?: string | number
	disabled?: boolean
	className?: string
	toggleClassName?: string
	toggleIconClassName?: string
	style?: CSSProperties
	placeholder?: string
}

export type SelectItems = SelectItem[]

export type SelectItem = {
	label: string | ReactNode
	value: string
	className?: string
}

export type { UseSelectStateChange }

export const SelectList = forwardRef<
	HTMLUListElement,
	ComponentProps<typeof motion.ul>
>(function SelectList(props, ref) {
	return (
		<motion.ul
			initial="closed"
			animate="open"
			exit="closed"
			transition={{ type: 'spring', stiffness: 800, damping: 40 }}
			variants={{
				open: { opacity: 1, scaleY: 1 },
				closed: { opacity: 0, scaleY: 0 },
			}}
			data-testid="select-list"
			{...props}
			className={clsx(
				props.className,
				'absolute -left-5 top-full m-0 mt-1 max-h-80 origin-[top_center] overflow-y-auto rounded-xl bg-blue-dark p-2.5 text-sm shadow-raised outline-none'
			)}
			ref={ref}
		/>
	)
})

export const SelectListItem = forwardRef<
	HTMLLIElement,
	ComponentProps<'li'> & { value: string; isHighlighted: boolean }
>(function SelectListItem({ value, isHighlighted, className, ...props }, ref) {
	return (
		<li
			{...props}
			className={clsx(
				className,
				`select-value-${value}`,
				'm-0 cursor-pointer list-none p-2.5',
				isHighlighted ? 'text-yellow' : 'text-white'
			)}
			ref={ref}
		/>
	)
})

/**
 * A control that provides a menu of options, also commonly referred to as a
 * dropdown. It should be used in place of the `<select>` as it provides similar
 * functionality with a custom look and feel.
 *
 * @see https://github.com/downshift-js/downshift
 *
 * @example
 * <Select
 *   items={[
 *     { label: 'Red', value: 'red' },
 *     { label: 'Green', value: 'green' },
 *   ]}
 * />
 */
const Select = ({
	items = [],
	width = '150px',
	placeholder = 'Select...',
	disabled,
	className,
	toggleClassName,
	toggleIconClassName,
	style,
	...props
}: Props) => {
	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
	} = useSelect<SelectItem>({
		items,
		itemToString: (i) => i?.value || '',
		...props,
	})

	return (
		<div
			className={clsx(className, 'relative', isOpen ? 'z-3' : 'z-2')}
			style={style}
		>
			<button
				{...getToggleButtonProps({ disabled })}
				type="button"
				role="button"
				className={clsx(
					toggleClassName,
					selectedItem && `select-value-${selectedItem.value}`,
					'inline-flex cursor-pointer appearance-none items-center border-none bg-transparent p-0 transition-all hover:text-yellow focus-visible:text-yellow',
					isOpen
						? 'text-yellow'
						: selectedItem
							? 'text-white'
							: 'text-gray'
				)}
			>
				{selectedItem ? selectedItem.label : placeholder}

				<motion.div
					className={clsx(
						toggleIconClassName,
						'ml-1 flex origin-center items-center justify-center transition-colors duration-75'
					)}
					animate={{ rotate: isOpen ? 180 : 0 }}
				>
					<ChevronDown size={14} />
				</motion.div>
			</button>

			<SelectList
				{...getMenuProps()}
				style={{ width }}
				animate={isOpen ? 'open' : 'closed'}
			>
				{items.map((option, index) => (
					<SelectListItem
						key={option.value}
						{...getItemProps({ item: option, index })}
						value={option.value}
						isHighlighted={highlightedIndex === index}
						className={option.className}
					>
						{option.label}
					</SelectListItem>
				))}
			</SelectList>
		</div>
	)
}

export { useSelect }

export default Select
