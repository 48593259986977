import { genericErrorMessage, unauthorisedErrorMessage } from './error'
import { parseNumber } from './number'

export const API_ENDPOINT =
	import.meta.env.VITE_API_ENDPOINT ||
	process.env.VITE_API_ENDPOINT ||
	`http://${window.location.host}`

const defaultMaxUploadSize = 16
export const MAX_UPLOAD_SIZE_MB = Number.isNaN(
	parseNumber(import.meta.env.VITE_MAX_UPLOAD_SIZE_MB)
)
	? defaultMaxUploadSize
	: parseNumber(import.meta.env.VITE_MAX_UPLOAD_SIZE_MB)

type APIRequestOptions = {
	path: string
	token: string
	method?: string
	payload?: unknown
	contentType?: string
	abortController?: AbortController
}

export async function apiRequest({
	path,
	token,
	method = 'GET',
	payload,
	contentType = 'application/json',
	abortController,
}: APIRequestOptions) {
	if (!path) {
		throw new Error('No path given for API request')
	}

	const response = await fetch(API_ENDPOINT + path, {
		method,
		headers: {
			'Content-Type': contentType,
			Authorization: `Bearer ${token}`,
		},
		body: payload ? JSON.stringify(payload) : undefined,
		signal: abortController?.signal,
	})

	if (!response.ok) {
		if (response.status === 403 || response.status === 401) {
			throw new Error(unauthorisedErrorMessage)
		}

		let errors: { message: string }[]

		try {
			const json = await response.json()

			if (!json || !Array.isArray(json.errors) || !json.errors.length) {
				throw new Error()
			}

			errors = json.errors
		} catch {
			throw new Error(genericErrorMessage)
		}

		errors = errors.filter((e) => !!e.message)

		if (!errors.length) {
			throw new Error(genericErrorMessage)
		}

		throw new Error(errors.map((e) => e.message).join(', '))
	}

	return response
}
