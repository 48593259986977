import Button from 'core/components/Button'
import Dialog from 'core/components/Dialog'
import Heading from 'core/components/Heading'
import { Lock } from 'core/components/Icon'
import { useCallback, useEffect, useState } from 'react'
import { useIdle } from 'react-use'
import { useLogout } from './Logout'

const WARNING_TIMEOUT_MS = 30000 // 30 second warning timeout

type Props = {
	timeoutMins: number
}

/** Logs the user out if inactive for a certain amount of time. It shows a popup
 * for the user to be able to stay logged in if they click a button. */
export default function InactivityLogout({ timeoutMins }: Props) {
	const isIdle = useIdle(timeoutMins * 60000)
	const logout = useLogout()
	const [timeoutId, setTimeoutId] = useState<number | undefined>()
	const [showWarning, setShowWarning] = useState(false)
	const [isLoggingOut, setLoggingOut] = useState(false)

	// Initiates the logout
	const initiateLogout = useCallback(() => {
		setLoggingOut(true)
		logout(
			`${window.location.origin}/auth/signedout?returnTo=${encodeURIComponent(window.location.toString())}`
		)
	}, [logout])

	// Cancels the auto-logout.
	const cancelLogout = useCallback(() => {
		window.clearTimeout(timeoutId)
		setShowWarning(false)
		setTimeoutId(undefined)
		setLoggingOut(false)
	}, [timeoutId])

	useEffect(() => {
		// Once the user is idle, if the page is visible then show a warning and
		// set a timeout to auto-logout otherwise logout straight away.
		if (isIdle && !showWarning) {
			if (document.visibilityState === 'visible') {
				// If the user has the tab visible, show a warning with a chance to
				// stay logged in.
				setTimeoutId(
					window.setTimeout(initiateLogout, WARNING_TIMEOUT_MS)
				)
				setShowWarning(true)
			} else {
				// The tab is hidden so just logout.
				initiateLogout()
			}
		}
		// If the user becomes active while the warning is displayed, cancel the
		// logout. This covers the case where the user wiggles the mouse to
		// continue being logged in.
		if (!isIdle && showWarning) {
			cancelLogout()
		}
		return () => {
			if (!showWarning) {
				window.clearTimeout(timeoutId)
			}
		}
	}, [isIdle, showWarning, timeoutId, initiateLogout, cancelLogout])

	return (
		<Dialog
			aria-label="Inactive Session"
			className="flex max-w-md flex-col items-center justify-center text-center"
			isOpen={showWarning}
			onDismiss={cancelLogout}
		>
			<Lock size={48} className="animate-bounce text-gray" />

			<Heading size={4} className="mt-4">
				You&apos;re about to be signed out
			</Heading>

			<p className="mt-5 text-sm text-gray">
				Your organization&apos;s policy enforces automatic sign out
				after a period of inactivity.
			</p>

			<Button
				size="large"
				disabled={isLoggingOut}
				className="mt-7"
				onClick={cancelLogout}
			>
				{isLoggingOut ? 'Signing out...' : 'Stay signed in'}
			</Button>
		</Dialog>
	)
}
