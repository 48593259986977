import clsx from 'clsx'
import { CheckCircle, PlusCircle, Slash } from 'core/components/Icon'
import { Transition, motion } from 'framer-motion'
import { ComponentPropsWithoutRef, ReactNode, useId } from 'react'
import { distributionGradientBorder } from './gradientBorder'

type Props = Omit<ComponentPropsWithoutRef<'label'>, 'children'> & {
	name: ReactNode
	description: string
	firmCount: number
	checked: boolean
	disabled?: boolean
	collapsed?: boolean
	compact?: boolean
	onChecked: (checked: boolean) => void
}

export const TRANSITION: Transition = {
	type: 'spring',
	damping: 33,
	stiffness: 333,
}

const DistributionInterestOption = ({
	name,
	description,
	firmCount,
	checked,
	disabled = false,
	collapsed = false,
	compact = false,
	className,
	onChecked,
	...props
}: Props) => {
	const labelId = useId()
	const descriptionId = useId()
	return (
		<motion.div
			initial={false}
			transition={TRANSITION}
			animate={{
				height: collapsed ? 62 : compact ? 132 : 200,
			}}
			className="flex select-none flex-col overflow-hidden whitespace-nowrap"
		>
			<label
				{...props}
				className={clsx(
					'relative flex-1',
					distributionGradientBorder({
						bgColor: 'blue-dark',
						textColor: 'gray-dark',
						hoverable: true,
						focusable: true,
						active: checked,
						disabled,
					}),
					className
				)}
			>
				<input
					type="checkbox"
					checked={checked}
					aria-labelledby={labelId}
					aria-describedby={descriptionId}
					disabled={disabled}
					className="absolute h-0 w-0 opacity-0"
					onChange={(evt) => onChecked(evt.target.checked)}
				/>

				<motion.div
					className="relative"
					initial={false}
					transition={TRANSITION}
					animate={{
						y: collapsed ? 12 : compact ? 132 / 2 : 200 / 2,
						x: collapsed ? 24 : '50%',
					}}
				>
					<motion.div
						className={clsx(
							'absolute transition-colors',
							checked ? 'stroke-yellow' : 'stroke-gray-dark'
						)}
						initial={false}
						transition={TRANSITION}
						animate={{
							x: collapsed ? 0 : '-50%',
							y: collapsed ? 0 : -48,
						}}
					>
						{checked ? (
							<CheckCircle
								size={32}
								className="stroke-inherit stroke-[1.666] animate-icon-lines-mid"
							/>
						) : disabled ? (
							<Slash
								size={32}
								className="stroke-gray-dark stroke-[1.666] opacity-50 animate-icon-lines-mid"
							/>
						) : (
							<PlusCircle
								size={32}
								className="stroke-inherit stroke-[1.666] animate-icon-lines-mid"
							/>
						)}
					</motion.div>

					<motion.h3
						id={labelId}
						className={clsx(
							'absolute text-sm font-bold leading-snug',
							disabled ? 'text-gray opacity-80' : 'text-white'
						)}
						initial={false}
						transition={TRANSITION}
						animate={{
							x: collapsed ? 44 : '-50%',
							y: collapsed ? 7 : compact ? -5 : 0,
						}}
					>
						{name}
					</motion.h3>
				</motion.div>

				<motion.div className="relative translate-x-1/2">
					<motion.p
						id={descriptionId}
						initial={false}
						transition={TRANSITION}
						animate={{
							opacity: collapsed ? 0 : 1,
							x: '-50%',
							y: collapsed ? 72 : compact ? 84 : 125,
						}}
						className={clsx(
							'absolute origin-top text-sm leading-snug text-gray',
							disabled && '!opacity-60'
						)}
					>
						{description}
					</motion.p>
				</motion.div>

				<div
					className={clsx(
						'absolute right-6 whitespace-nowrap text-sm transition-colors',
						checked ? 'text-yellow' : 'text-gray',
						disabled && 'opacity-60',
						collapsed ? 'top-[1.1875rem]' : 'top-5'
					)}
				>
					<strong>{firmCount}</strong>
					{firmCount === 1 ? ' firm' : ' firms'}
				</div>
			</label>
		</motion.div>
	)
}

export default DistributionInterestOption
