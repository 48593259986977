import { useEffect } from 'react'
import { OperationContext } from 'urql'

type UsePollingParams = {
	/** polling interval in milliseconds */
	interval: number
	/** whether the polling should be paused */
	pause?: boolean
	/** the query refetch function - returned by useQuery */
	refetch: (opts?: Partial<OperationContext>) => void
}

/**
 * Polling provides near-real-time synchronization by causing a query to execute
 * periodically at a specified interval. The interval is given in milliseconds.
 */
export function usePolling({ interval, pause, refetch }: UsePollingParams) {
	useEffect(() => {
		if (!pause && interval > 0) {
			const id = setInterval(() => {
				// skip while page is hidden
				if (document.hidden) return

				refetch({ requestPolicy: 'network-only' })
			}, interval)
			return () => clearInterval(id)
		}
	}, [interval, pause, refetch])
}
