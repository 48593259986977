import clsx from 'clsx'
import { ellipsis } from 'core/styles/text'
import { ComponentPropsWithoutRef, ElementType } from 'react'

type Props<T extends ElementType> = {
	as?: T
	size?: 1 | 2 | 3 | 4 | 5 | 6
	truncate?: boolean
}

/**
 * A title at the head of a page or section. Helpful for ensuring the correct
 * semantic HTML heading tag without restriction on which heading style to use.
 *
 * @example
 * <Heading>Unlocking liquidity</Heading>
 * <Heading size={2}>Unlocking liquidity</Heading>
 * <Heading as="h1" size={3}>Unlocking liquidity</Heading>
 * <Heading truncate>Unlocking liquidity</Heading>
 *
 */
function Heading<T extends ElementType = 'h1'>({
	as,
	size = 1,
	truncate,
	...props
}: Props<T> & Omit<ComponentPropsWithoutRef<T>, keyof Props<T>>) {
	const Component = as || 'h1'
	return (
		<Component
			{...props}
			className={clsx(
				props.className,
				'font-bold',
				size === 1 && 'text-5xl leading-[1.1] -tracking-[.004em]',
				size === 2 && 'text-4xl leading-[1.15] -tracking-[.003em]',
				size === 3 && 'text-3xl leading-tight -tracking-[.002em]',
				size === 4 && 'text-2xl leading-8 -tracking-[.002em]',
				size === 5 && 'text-xl leading-snug',
				size === 6 && 'text-base leading-snug',
				truncate && ellipsis
			)}
		/>
	)
}

export default Heading
