import clsx from 'clsx'
import Loading from 'core/components/Loading'
import { AnimatePresence, motion } from 'framer-motion'
import { ComponentProps, ReactNode, forwardRef } from 'react'
import { InputColor } from '../Input'
import classes from './index.module.css'

type Props = ComponentProps<'label'> & {
	loading?: boolean
	after?: ReactNode
	before?: ReactNode
	color?: InputColor // defaults to yellow
	compact?: boolean
}

const Adornment = ({
	isVisible,
	side,
	compact,
	children,
	className,
	...props
}: ComponentProps<typeof motion.span> & {
	isVisible: boolean
	side: 'left' | 'right'
	compact: boolean
}) => (
	<AnimatePresence initial={false}>
		{!!isVisible && (
			<motion.span
				initial={{ width: 0 }}
				animate={{ width: 'auto' }}
				exit={{ width: 0 }}
				{...props}
				className={clsx(
					classes.adornment,
					side === 'left' && '-order-1',
					className
				)}
			>
				<motion.span
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className={clsx(
						'flex h-full items-center',
						side === 'left' && (compact ? 'pr-0.5' : 'pr-2.5'),
						side === 'right' && (compact ? 'pl-0.5' : 'pl-2.5')
					)}
				>
					{children}
				</motion.span>
			</motion.span>
		)}
	</AnimatePresence>
)

/**
 * Helps with showing contextual input information on either side of an `<Input/>` component.
 * You can set content before and after the input and also show a loading state.
 *
 * @example
 * <InputAdorned before="EUR">
 *   <Input placeholder="100.00" />
 * </InputAdorned>
 */
const InputAdorned = forwardRef<HTMLLabelElement, Props>(function InputAdorned(
	{
		before,
		after,
		loading = false,
		color = 'yellow',
		compact = false,
		children,
		className,
		...props
	},
	ref
) {
	return (
		<label
			{...props}
			ref={ref}
			className={clsx(
				'relative inline-flex w-full selection:text-blue-darkest',
				color === 'yellow' && 'text-yellow selection:bg-yellow',
				color === 'magenta' && 'text-magenta selection:bg-magenta',
				color === 'green' && 'text-green selection:bg-green',
				color === 'gray' && 'text-gray selection:bg-gray',
				color === 'white' && 'text-white selection:bg-white',
				color === 'gray-dark' &&
					'text-gray-dark selection:bg-gray-dark',
				color === 'gray-light' &&
					'text-gray-light selection:bg-gray-light',
				classes.wrapper,
				className
			)}
		>
			{children}

			<Adornment isVisible={!!before} side="left" compact={compact}>
				{before}
			</Adornment>

			<Adornment isVisible={!!after} side="right" compact={compact}>
				{after}
			</Adornment>

			<Adornment isVisible={!!loading} side="right" compact={compact}>
				<Loading color={color} />
			</Adornment>
		</label>
	)
})

export default InputAdorned
