import clsx from 'clsx'
import TextLabel from 'core/components/TextLabel'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { ComponentProps, forwardRef } from 'react'

type Props = ComponentProps<typeof TextLabel> & {
	createdAt: string
	readAt?: string | null
	title: string
	description: string
	highlighted?: boolean
}

const Notification = forwardRef<HTMLDivElement, Props>(function Notification(
	{ highlighted, readAt, createdAt, ...props },
	ref
) {
	const createdAtDate = parseISO(createdAt)
	const unread = !readAt
	const read = !!readAt
	return (
		<TextLabel
			label={props.title}
			{...props}
			ref={ref}
			color={readAt ? 'gray' : 'yellow'}
			className={clsx(
				'cursor-pointer whitespace-normal px-5 py-3 text-sm leading-tight transition-colors',
				unread && 'bg-blue-darkish text-white',
				read && 'text-gray',
				highlighted && '!bg-blue-darker',
				props.className
			)}
		>
			<p className="mt-1">{props.description}</p>

			<p
				title={format(createdAtDate, 'PPPppp')}
				className="mt-1 text-2xs text-gray"
			>
				{formatDistanceToNow(createdAtDate)} ago
			</p>
		</TextLabel>
	)
})

export default Notification
