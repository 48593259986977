import DistributionForm, {
	Distribution,
} from 'bookbuilds/components/DistributionForm'
import { Page } from 'core/components/Page'
import { formatBloombergTicker } from 'core/utils/tickers'
import { useMemo } from 'react'
import { Data } from '.'

type Props = {
	data: Data
	onStart: (distribution: Distribution) => Promise<void>
	onCancel: () => Promise<void>
}

export default function DistributeWithTokenForm({
	data,
	onStart,
	onCancel,
}: Props) {
	const initialDistribution = useMemo(() => {
		const distribution: Distribution = {
			includeLiveWatchlists: data.liveWatchlists,
			includePassiveInterest: data.passiveInterest,
			includePriceDiscovery: data.priceDiscovery,
			orgIds: data.manualOrgIds || [],
			excludedOrgIds: data.excludedOrgIds || [],
		}

		return distribution
	}, [data])

	const bbgTicker = formatBloombergTicker(data.primaryBloombergTicker)

	return (
		<Page
			title="BookBuild Distribution"
			className="items-center justify-center lg:items-start"
		>
			<div className="flex w-full max-w-[1200px] flex-1 animate-fade-in flex-col px-[4.25rem] pb-0 pt-8">
				<header className="mb-6 flex flex-col md:justify-between lg:flex-row lg:items-center">
					<h1 className="mr-5 whitespace-nowrap text-2xl font-bold">
						Complete your BookBuild
					</h1>
				</header>

				<DistributionForm
					bookbuildId={data.bookbuildId}
					bookbuildEndsAt={data.endsAt}
					instrumentDescription={data.instrumentName}
					instrumentMarketName={data.marketName}
					instrumentTicker={bbgTicker}
					initial={initialDistribution}
					distributionOrgs={data.distributionOrgs || []}
					holders={data.holders || []}
					previewReach={data.previewReach}
					onStart={onStart}
					onCancel={onCancel}
				/>
			</div>
		</Page>
	)
}
