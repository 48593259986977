// Based on: https://github.com/GoogleCloudPlatform/stackdriver-errors-js
import { captureException } from '@sentry/react'
import StackTrace from 'stacktrace-js'

type ServiceContext = {
	service: string
}

type ErrorContext = {
	user?: string
	httpRequest?: {
		userAgent: string
		url: string
	}
}

type Payload = {
	message: string
	serviceContext: ServiceContext
	context: ErrorContext
}

//
// Config
//
const PROJECT_ID = import.meta.env.VITE_PROJECT_ID
const API_KEY = import.meta.env.VITE_ERROR_REPORTER_API_KEY
export const ENABLED = import.meta.env.VITE_ERROR_REPORTER_ENABLED || false
const serviceContext: ServiceContext = { service: 'web' }
const context: ErrorContext = {}

//
// Endpoints
//
const BASE_API_URL =
	'https://clouderrorreporting.googleapis.com/v1beta1/projects/'
const REPORT_URL = BASE_API_URL + PROJECT_ID + '/events:report?key=' + API_KEY

//
// Register the Error Reporter
//
export function registerErrorReporter() {
	if (!ENABLED) return
	if (!API_KEY) {
		throw new Error(
			'[Error Reporter] Cannot initialize: No API key provided.'
		)
	}
	if (!PROJECT_ID) {
		throw new Error(
			'[Error Reporter] Cannot initialize: No Project ID provided.'
		)
	}
	registerHandlers()
}

//
// Report an Error
//
export async function sendErrorReport(err: unknown) {
	if (!ENABLED) {
		console.error(err)
		return
	}

	if (!err) {
		return Promise.reject(new Error('no error to report'))
	}

	if (!(err instanceof Error)) {
		return
	}

	try {
		// Capture exception in Sentry
		captureException(err)

		const message = await resolveError(err, 1)

		const payload: Payload = {
			message,
			serviceContext,
			context: {
				...context,
				httpRequest: {
					userAgent: window.navigator.userAgent,
					url: window.location.href,
				},
			},
		}

		await sendErrorPayload(payload)
	} catch (err) {
		// We can't let reporting errors bubble up or they will get themselves
		// reported too and it ends up here endlessly.
		console.error(err)
	}
}

export function setErrorUserId(userId?: string) {
	context.user = userId
}

//
// Register error handlers
//
function registerHandlers() {
	const oldErrorHandler = window.onerror

	// report uncaught exceptions
	window.onerror = (message, source, lineno, colno, error) => {
		if (error) {
			sendErrorReport(error)
		}
		if (oldErrorHandler) {
			oldErrorHandler(message, source, lineno, colno, error)
		}
		return true
	}

	const oldPromiseRejectionHandler = window.onunhandledrejection

	// report unhandled promise rejections
	window.onunhandledrejection = (
		promiseRejectionEvent: PromiseRejectionEvent
	) => {
		if (promiseRejectionEvent) {
			sendErrorReport(promiseRejectionEvent.reason)
		}
		if (oldPromiseRejectionHandler) {
			oldPromiseRejectionHandler.bind(window)(
				promiseRejectionEvent.reason
			)
		}
		return true
	}
}

//
// Resolve an Error
//
function resolveError(err: Error, firstFrameIndex: number) {
	const error = err as Error & {
		file: string
		line: number
		column: number
	}
	// This will use sourcemaps and normalize the stack frames
	return StackTrace.fromError(error).then(
		function (stack) {
			const lines = [error.toString()]
			// Reconstruct to a JS stackframe as expected by Error Reporting parsers.
			for (let s = firstFrameIndex; s < stack.length; s++) {
				// Cannot use stack[s].source as it is not populated from source maps.
				lines.push(
					[
						'    at ',
						// If a function name is not available '<anonymous>' will be used.
						stack[s].getFunctionName() || '<anonymous>',
						' (',
						stack[s].getFileName(),
						':',
						stack[s].getLineNumber(),
						':',
						stack[s].getColumnNumber(),
						')',
					].join('')
				)
			}
			return lines.join('\n')
		},
		function (reason) {
			// Failure to extract stacktrace
			return [
				'Error extracting stack trace: ',
				reason,
				'\n',
				error.toString(),
				'\n',
				'    (',
				error.file,
				':',
				error.line,
				':',
				error.column,
				')',
			].join('')
		}
	)
}

async function sendErrorPayload(payload: Payload) {
	const response = await fetch(REPORT_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json; charset=UTF-8',
		},
		body: JSON.stringify(payload),
	})
	const data = await response.json()
	if (data.error) return
	return data
}
