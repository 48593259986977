import clsx from 'clsx'
import useDocumentTitle from 'core/hooks/useDocumentTitle'
import {
	ComponentProps,
	createContext,
	Context as ReactContext,
	useContext,
	useMemo,
} from 'react'
import PageContent from './PageContent'
import PageHeader from './PageHeader'
import PageSidebar from './PageSidebar'

type PageContext = {
	title: string
}

type Props = ComponentProps<'section'> & PageContext

const Context: ReactContext<PageContext> = createContext({} as PageContext)

export function usePageContext(): PageContext {
	return useContext(Context)
}

/**
 * Ensures a consistent layout across pages. It supports sidebars and correctly
 * sets the browser window title.
 *
 * @example
 * <Page title="My Page">
 *   <PageSidebar>Sidebar content</PageSidebar>
 *   <PageContent>
 *     <PageHeader title="My Section" icon={Target} />
 *     Page content
 *   </PageContent>
 * </Page>
 */
function Page({ title, className, children }: Props) {
	useDocumentTitle(title)

	const context = useMemo(
		(): PageContext => ({
			title,
		}),
		[title]
	)

	return (
		<Context.Provider value={context}>
			<section
				className={clsx(className, 'flex flex-1 flex-col xs:flex-row')}
			>
				{children}
			</section>
		</Context.Provider>
	)
}

export { Page, PageContent, PageHeader, PageSidebar }
