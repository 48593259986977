import { useMeQuery, UserFragment } from 'graphql/generated'
import { CombinedError } from 'urql'

export type User = Partial<UserFragment> & {
	fetching?: boolean
	error?: CombinedError
}

export function useUser(): User {
	const [{ data, fetching, error }] = useMeQuery()

	const user = data?.me

	return {
		fetching,
		error,
		...user,
	}
}
