import numeral from 'numeral'

// Format number
export function formatNumber(
	number?: number | string | null,
	format?: string
): string {
	if (typeof number === 'undefined' || number === null || number === '') {
		return ''
	}
	return numeral(number).format(format || '0,0')
}

// Format price
export function formatPrice(
	price?: number | string | null,
	currency?: string
): string {
	if (typeof price === 'undefined' || price === null) return ''
	const formatted = numeral(price).format('0,0.00')
	return currency ? `${currency.toUpperCase()} ${formatted}` : formatted
}

// parseNumber parses a given number that may be a string. If the number can't
// be parsed, it returns NaN (not a number).
export function parseNumber(number?: number | string): number {
	if (typeof number === 'undefined') {
		return NaN
	}

	const num = numeral(number).value()

	if (num === null) {
		return NaN
	}

	return num
}

// Parse number as int
export function parseNumberInt(number?: number | string): number {
	if (typeof number === 'undefined') return NaN
	return Math.round(parseNumber(number))
}

// Uses a conventional letter after each number to specify powers of 10
export function abbreviateNumber(number: number | string): string {
	return numeral(number).format('0[.]0 a').trim()
}

// Same as above but allows 2 decimals
export function abbreviatePrice(number: number | string): string {
	return numeral(number).format('0.00 a').trim()
}

export function percentOf(value: number, base: number) {
	return numeral(value / base).format('0[.]0%')
}

export function percentOfPrice(price: number, sharePrice: number) {
	const discount = price / sharePrice - 1
	const prefix = discount > 0 ? '+' : discount < 0 ? '-' : ''
	return prefix + numeral(Math.abs(discount)).format('0.00%')
}

// gbxToGbpPrice a GBX amount into its GPB equivalent
export function gbxToGbpPrice(
	gbxAmount: number | string,
	format?: string
): string {
	return numeral(gbxAmount)
		.divide(100)
		.format(format || '0,0.00')
}

/**
 * **IMPORTANT**: Keep in mind this expects a percent value where 1 = 100%, so
 * it multiplies by 100 automatically
 */
export function formatPercent(value?: string | number | null) {
	const num = numeral(value)
	const numValue = num.value()

	if (typeof value === 'undefined' || value === null || numValue === null) {
		return ''
	}

	if (numValue > 0 && numValue < 0.0001) {
		return '<0.01%'
	}

	return numeral(value).format(`0,0[.]0[0]%`)
}

export function formatAdtv(daysADTV?: string | number | null) {
	if (typeof daysADTV === 'undefined' || daysADTV === null) return ''

	if (parseNumber(daysADTV) < 0.01) {
		return '<0.01'
	}

	if (parseNumber(daysADTV) < 1) {
		return formatNumber(daysADTV, '0[.]0[0]')
	}

	return formatNumber(daysADTV, '0[.]0')
}

export function intToString(n?: number) {
	return typeof n === 'number' && !isNaN(n) ? formatNumber(n) : ''
}

export function stringToInt(n: string) {
	const parsed = parseNumberInt(n)
	return !isNaN(parsed) ? parsed : undefined
}

export function calculateNotionalCash(
	currency: string,
	volume: number,
	price: number
) {
	if (currency.toUpperCase() === 'GBX') {
		return gbxToGbpPrice(volume * price)
	}

	return formatPrice(volume * price)
}

export function formatLISAndLISCurrency(lis: number, currency: string) {
	let formattedlis = formatNumber(lis, '0,0')
	let lisCurrency = currency.toUpperCase()

	if (lisCurrency === 'GBX') {
		formattedlis = gbxToGbpPrice(lis, '0,0')
		lisCurrency = 'GBP'
	}

	return `${lisCurrency} ${formattedlis}`
}
