import clsx from 'clsx'
import { ComponentProps, FC } from 'react'

/**
 * Helpful for displaying a subtle centered placeholder message in a large area.
 * A good example is "No <resource> yet" in the space where the resources will
 * eventually be displayed.
 *
 * @example
 * <Notice>No opportunities yet</Notice>
 */
const Notice: FC<ComponentProps<'div'>> = ({ className, ...props }) => (
	<div
		{...props}
		className={clsx(
			className,
			'flex flex-1 animate-fade-in flex-col items-center justify-center text-gray animate-duration-slow'
		)}
	/>
)

export default Notice
