import clsx from 'clsx'
import { ComponentProps, FC } from 'react'

type Props = ComponentProps<'svg'>

const TrendArrow: FC<Props> = ({ ...props }: Props) => (
	<svg
		{...props}
		fill="none"
		viewBox="0 0 10 13"
		xmlns="http://www.w3.org/2000/svg"
		className={clsx(props.className)}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 0.333334C5.55228 0.333334 6 0.781049 6 1.33333V12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12V1.33333C4 0.781049 4.44772 0.333334 5 0.333334Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683417 6.09763 0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893Z"
		/>
	</svg>
)

export default TrendArrow
