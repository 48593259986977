import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import classes from './index.module.css'

type Props = ComponentProps<'div'> & {
	color?: LoadingColor
}

export type LoadingColor = (typeof colors)[number]

export const colors = [
	'yellow',
	'magenta',
	'green',
	'gray',
	'gray-dark',
	'gray-light',
	'white',
	'blue-darkest',
	'blue-highlight',
] as const

/**
 * Similar to spinners, loading indicators are used when retrieving data or
 * performing slow computations, and help to notify users that loading is
 * underway. The key difference is that Loading indicators are meant to be used
 * inline.
 *
 * @example
 * <Loading />
 * <Loading color="blue" />
 */
const Loading: FC<Props> = ({ color = 'yellow', className, ...props }) => (
	<div
		{...props}
		className={clsx(
			'relative inline-flex aspect-[5/1] w-auto animate-fade-in justify-around text-center align-middle',
			!className?.includes('h-') && 'h-1.5', // add a default height if not given
			className
		)}
	>
		{Array.from({ length: 3 }).map((_, i) => (
			<div
				key={i}
				className={clsx(
					'aspect-square h-full rounded-full',
					classes['animate-dot'],
					color === 'yellow' && 'bg-yellow',
					color === 'magenta' && 'bg-magenta',
					color === 'green' && 'bg-green',
					color === 'gray' && 'bg-gray',
					color === 'gray-light' && 'bg-gray-light',
					color === 'gray-dark' && 'bg-gray-dark',
					color === 'gray-light' && 'bg-gray-light',
					color === 'white' && 'bg-white',
					color === 'blue-darkest' && 'bg-blue-darkest',
					color === 'blue-highlight' && 'bg-blue-highlight'
				)}
				style={{ animationDelay: 100 * (i + 1) + 'ms' }}
			/>
		))}
	</div>
)

export default Loading
