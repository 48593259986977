import { getRemoteVersion, VERSION as localVersion } from 'core/utils/version'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'

const AppVersion = () => {
	const [newVersionAvailable, setNewVersionAvailable] = useState(false)

	// Check if there's a new version every 30 seconds
	useEffect(() => {
		const id = setInterval(async () => {
			const remoteVersion = await getRemoteVersion()

			if (remoteVersion) {
				setNewVersionAvailable(remoteVersion !== localVersion)
			}
		}, 30000)

		return () => clearInterval(id)
	}, [])

	return (
		<>
			<span>{localVersion.replace('-stable', '')}</span>

			<AnimatePresence>
				{newVersionAvailable && (
					<motion.span
						initial={{ width: 0 }}
						animate={{ width: 'auto' }}
						exit={{ width: 0 }}
						transition={{
							type: 'spring',
							damping: 33,
							stiffness: 66,
						}}
						className="overflow-hidden"
					>
						<button
							type="button"
							className="whitespace-nowrap rounded bg-magenta px-1.5 font-bold text-white"
							onClick={() => {
								location.reload()
							}}
						>
							New version available — Click to reload
						</button>
					</motion.span>
				)}
			</AnimatePresence>
		</>
	)
}

export default AppVersion
