import clsx from 'clsx'
import TrendArrow from 'core/components/Icon/TrendArrow'
import { HoldingTrend } from 'graphql/generated'
import { ComponentProps } from 'react'
import { ArrowUpCircle, CheckCircle, Icon, Loader, Target } from 'react-feather'
import classes from './index.module.css'

export * from 'react-feather'

type Props = ComponentProps<'svg'> & {
	trend: HoldingTrend
}

export const TrendIcon = ({ trend, className }: Props) => (
	<span
		className={clsx(
			'flex h-[22px] w-[22px] flex-none items-center justify-center rounded-full',
			trend === HoldingTrend.Up &&
				'bg-green bg-opacity-[0.15] text-green',
			trend === HoldingTrend.Down &&
				'bg-magenta bg-opacity-25 text-magenta',
			trend === HoldingTrend.Flat &&
				'bg-blue bg-opacity-[0.15] text-blue',
			className
		)}
	>
		<TrendArrow
			className={clsx(
				'h-[13px] w-[10px]',
				trend === HoldingTrend.Up && 'fill-green',
				trend === HoldingTrend.Down && 'rotate-180 fill-magenta',
				trend === HoldingTrend.Flat && 'rotate-90 fill-blue'
			)}
		/>
	</span>
)

export const LoaderAnimated: Icon = ({ className, ...props }) => (
	<Loader
		{...props}
		className={clsx(className, classes['loader-animated'])}
	/>
)

export const ArrowUpNoCircle: Icon = ({ className, ...props }) => (
	<ArrowUpCircle
		{...props}
		className={clsx(className, classes['arrow-up-no-circle'])}
	/>
)

export const TargetAnimated: Icon = ({ className, ...props }) => (
	<Target
		{...props}
		className={clsx(className, classes['target-animated'])}
	/>
)

export const CheckCircleAnimated: Icon = ({ className, ...props }) => (
	<CheckCircle {...props} className={clsx(className, 'animate-icon')} />
)
