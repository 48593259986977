import AdvNotAvailable from 'core/components/AdvNotAvailable'
import AnimateVisibility from 'core/components/AnimateVisiblity'
import Placeholder from 'core/components/Placeholder'
import {
	calculateNotionalCash,
	formatNumber,
	formatPrice,
	parseNumber,
} from 'core/utils/number'
import { ComponentProps } from 'react'

type Props = Partial<ComponentProps<'div'>> & {
	isOpen: boolean
	targetVolume: number
	adtv?: number | null
	targetPrice: number
	currency: string
	upsizeVolume?: number | null
	upsizeAdtv?: number | null
	upsizePrice?: number | null
	brokerName: string
}

export default function BookbuildSummary({
	isOpen,
	targetVolume,
	adtv,
	targetPrice,
	currency,
	upsizeVolume,
	upsizeAdtv,
	upsizePrice,
	brokerName,
}: Props) {
	return (
		<AnimateVisibility initial={false} isVisible={isOpen}>
			<ul className="grid grid-flow-col grid-cols-2 justify-between gap-2 gap-y-5 pb-7 pl-1 pt-0 md:grid-flow-row md:grid-cols-[repeat(5,max-content)] lg:pl-0 xl:grid-cols-[repeat(11,max-content)]">
				<li className="flex flex-col gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						Target Volume
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{formatNumber(targetVolume)}
					</span>
				</li>

				<li className="flex flex-col gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						ADTV
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{adtv || (
							<AdvNotAvailable className="text-sm !font-bold text-gray-light text-opacity-80" />
						)}
					</span>
				</li>

				<li className="flex flex-col flex-nowrap gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						Target Price
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						<span className="text-gray-dark">{currency}</span>{' '}
						{formatPrice(targetPrice)}
					</span>
				</li>

				<li className="flex flex-col gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						{currency.toUpperCase() === 'GBX' ? 'GBP' : currency}
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{calculateNotionalCash(
							currency,
							parseNumber(targetVolume),
							parseNumber(targetPrice)
						)}
					</span>
				</li>

				<li
					className="hidden border-blue-darkish xl:my-2 xl:block xl:border-r"
					aria-hidden
				/>

				<li className="flex flex-col gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						Upsize Volume
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{typeof upsizeVolume === 'number' ? (
							formatNumber(upsizeVolume)
						) : (
							<Placeholder />
						)}
					</span>
				</li>

				<li className="flex flex-col gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						ADTV
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{upsizeAdtv ? (
							upsizeAdtv
						) : typeof upsizeVolume === 'number' ? (
							<AdvNotAvailable className="text-sm !font-bold text-gray-light text-opacity-80" />
						) : (
							<Placeholder />
						)}
					</span>
				</li>

				<li className="flex flex-col gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						Upsize Price
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{typeof upsizePrice === 'number' ? (
							<>
								<span className="text-gray-dark">
									{currency}
								</span>{' '}
								{formatPrice(upsizePrice)}
							</>
						) : (
							<Placeholder />
						)}
					</span>
				</li>

				<li className="flex flex-col gap-2">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						{currency.toUpperCase() === 'GBX' ? 'GBP' : currency}
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{!!upsizeVolume && !!upsizePrice ? (
							calculateNotionalCash(
								currency,
								parseNumber(upsizeVolume),
								parseNumber(upsizePrice)
							)
						) : (
							<Placeholder />
						)}
					</span>
				</li>

				<li
					className="my-2 hidden border-r border-blue-darkish xl:block"
					aria-hidden
				/>

				<li className="col-start-1 row-start-5 flex flex-col gap-2 md:col-start-5 md:row-start-1 xl:col-start-auto xl:row-start-auto">
					<span className="text-3xs font-bold uppercase text-gray text-opacity-80">
						Broker
					</span>
					<span className="text-sm font-bold uppercase text-gray-light text-opacity-80">
						{brokerName}
					</span>
				</li>
			</ul>
		</AnimateVisibility>
	)
}
