import clsx from 'clsx'
import Button from 'core/components/Button'
import { Check, X } from 'core/components/Icon'
import { ActionStatus } from 'core/utils/types'
import { ComponentProps, FC } from 'react'

type ButtonProps = ComponentProps<typeof Button>

type Props = Omit<ButtonProps, 'children' | 'loading'> & {
	label: string
	loadingLabel?: string
	completedLabel?: string
	failedLabel?: string
	status?: ActionStatus
	children?: never
}

/**
 * A specialised button meant for use in the following scenario:
 * - perform action on click
 * - control the status styles via simple state machine (e.g. initial -> loading -> completed)
 * - optionally pulse button to call attention
 *
 * @example
 * <ButtonAction
 *   label="Submit"
 *   loadingLabel="Submitting..."
 *   completedLabel="Submitted"
 *   status="initial"
 *   onClick={doSomethingGreat}
 * />
 */
const ButtonAction: FC<Props> = ({
	before = Check,
	color = 'yellow',
	status = 'initial',
	label,
	loadingLabel,
	completedLabel,
	failedLabel,
	disabled,
	variant,
	className = '',
	...props
}) => {
	let loading = false

	switch (status) {
		case 'initial':
			break
		case 'loading':
			before = null
			disabled = true
			loading = true
			if (loadingLabel) label = loadingLabel
			break

		case 'completed':
			before = Check
			disabled = true
			if (completedLabel) label = completedLabel
			color = 'green'
			className = clsx(className, '!opacity-100')
			break

		case 'failed':
			before = X
			disabled = true
			color = 'magenta'
			if (failedLabel) label = failedLabel
			className = clsx(className, '!opacity-100')
			break
	}

	return (
		<Button
			{...props}
			before={before}
			disabled={disabled}
			color={color}
			variant={variant}
			title={label}
			loading={loading}
			className={className}
		>
			{label}
		</Button>
	)
}

export default ButtonAction
