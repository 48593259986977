import clsx from 'clsx'
import Button from 'core/components/Button'
import { X } from 'core/components/Icon'
import theme from 'core/styles/theme'
import { AnimatePresence, motion } from 'framer-motion'
import {
	NotificationFragment,
	useMarkNotificationsAsReadMutation,
} from 'graphql/generated'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Notification from './Notification'
import { useNotificationsContext } from './Notifications'
import { WIDTH } from './NotificationsList'

type PopupNotification = NotificationFragment & {
	hidden?: boolean
}

// TIMEOUT is the time in milliseconds a popup notification is visible
const TIMEOUT = 6660

const TRANSITION = { type: 'spring', damping: 25, stiffness: 100 }

function PopupNotificationsList() {
	const navigate = useNavigate()

	const context = useNotificationsContext()

	const allNotifications = useMemo(
		() => context.response[0].data?.notifications.items,
		[context.response]
	)

	const [, markNotificationsAsRead] = useMarkNotificationsAsReadMutation()

	const [initialised, setInitialised] = useState(false)

	const [popupNotifications, setPopupNotifications] = useState<
		PopupNotification[]
	>([])

	// Whenever notifications are added to cache, create a list of
	// popup notifications that should be displayed.
	const newNotifications: PopupNotification[] | undefined = useMemo(() => {
		if (!allNotifications) return

		const currentIDs = popupNotifications?.map((n) => n.id)

		return (
			allNotifications
				// not currently part of the set of notifications
				.filter((n) => !currentIDs.includes(n.id))
				// add the hidden property so that it becomes a PopupNotification
				.map((n) => ({
					...n,
					hidden: !initialised || !!n.readAt,
				}))
		)
	}, [allNotifications, popupNotifications, initialised])

	useEffect(() => {
		// No notifications yet
		if (!allNotifications) return

		// Mark as initialised when the first notifications are available
		setInitialised(true)

		const allIDs = allNotifications.map((n) => n.id)

		// Remove popup notifications for notifications that have been deleted
		setPopupNotifications((ntfs) =>
			ntfs.filter((n) => allIDs.includes(n.id))
		)
	}, [allNotifications])

	// Whenever there are new notifications
	useEffect(() => {
		// Prevent infinitely re-setting notifications. Nothing to do.
		if (!newNotifications || !newNotifications.length) return

		// Add the new notifications to the list of popup notifications
		setPopupNotifications((ntfs) => [...ntfs, ...newNotifications])

		// Mark the new notifications as hidden after 30 seconds
		const idsToHide = newNotifications
			.filter((n) => !n.sticky)
			.map((n) => n.id)

		window.setTimeout(() => {
			setPopupNotifications((ntfs) =>
				ntfs.map((n) =>
					idsToHide.includes(n.id) ? { ...n, hidden: true } : n
				)
			)
		}, TIMEOUT)
	}, [newNotifications])

	const notifications = useMemo(
		() => popupNotifications.filter((n) => !n.hidden),
		[popupNotifications]
	)

	// hide marks the notification as read and immediately hides it.
	const hide = useCallback(
		(notificationId: string) => {
			markNotificationsAsRead({ ids: [notificationId] })

			setPopupNotifications((ntfs) =>
				ntfs.map((n) => {
					if (n.id === notificationId) {
						return { ...n, hidden: true }
					}
					return n
				})
			)
		},
		[markNotificationsAsRead]
	)

	const handlePopupClick = useCallback(
		(notification?: NotificationFragment) => {
			if (!notification || !notification.url) return
			navigate(notification.url)
			hide(notification.id)
		},
		[hide, navigate]
	)

	return (
		<div className="fixed bottom-0 right-0 z-10 mb-7 mr-7">
			<AnimatePresence>
				{notifications
					.slice(0)
					.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
					.map((notification, i) => {
						const lastItemIndex = 2
						const gap = 20

						// The Y position is a combination of moving the
						// notification up X times according to its array index
						// and then accounting for the number of margins too.
						//
						// For example, if this is the 3rd notification in the list:
						//
						// y = -300% - 40px
						//
						// - Moves itself up 3 times (-300%)
						// - Also accounts for 2 margins of 20px between the notifications
						//
						const positionPct = (i + 1) * 100
						const marginPxs = i * gap
						let y = `calc(-${positionPct}% - ${marginPxs}px)`
						const x0 = 'calc(100% + 30px)'
						const x1 = '-100%'

						let opacity = 1
						let boxShadow = theme.boxShadowRaised

						if (i > lastItemIndex) {
							// After a certain number of items, stack the
							// notifications behind the last allowed item to
							// prevent them infinitely going up.
							const lastItemPosition = (lastItemIndex + 1) * 100
							const lastItemMargin = lastItemIndex * gap

							// Slightly offset each notification to look like
							// they are stacked behind
							const offset =
								lastItemMargin + (i - lastItemIndex) * 10

							y = `calc(-${lastItemPosition}% - ${offset}px)`

							// Slightly fade out each stacked notification
							opacity = 1 - (i - lastItemIndex) * 0.3

							// Remove the shadow as it's not visible
							boxShadow = '0'
						}

						const { sticky, ...notificationProps } = notification

						return (
							<motion.div
								key={notification.id}
								initial={{ x: x0, y, opacity: 0, boxShadow }}
								animate={{ x: x1, y, opacity, boxShadow }}
								exit={{ x: x0, y, opacity: 0, boxShadow }}
								transition={TRANSITION}
								className={clsx(
									'absolute left-0 top-0 cursor-pointer rounded-xl bg-blue-darkish bg-opacity-60 backdrop-blur',
									sticky && 'border border-yellow'
								)}
								style={{ width: WIDTH, zIndex: 1000 - i }}
							>
								<Notification
									{...notificationProps}
									role="alert"
									aria-atomic="true"
									className="!bg-transparent px-5 py-3"
									onClick={() =>
										handlePopupClick(notification)
									}
								/>
								{!sticky && (
									<Button
										variant="bare"
										color="gray"
										onClick={() => hide(notification.id)}
										className="!absolute right-0 top-0 z-2 box-content h-3.5 w-3.5 p-3"
									>
										<X />
									</Button>
								)}
							</motion.div>
						)
					})}
			</AnimatePresence>
		</div>
	)
}
export default PopupNotificationsList
