import { format, parseISO } from 'date-fns'
import { enGB } from 'date-fns/locale'

export const defaultFormatOptions = { locale: enGB }

export function formatDate(
	date?: string | Date | null,
	type:
		| 'full'
		| 'long'
		| 'short'
		| 'time'
		| 'date'
		| 'date-nice'
		| 'date-long'
		| 'datetime'
		| 'datetimelong'
		| 'timeshort'
		| 'timedate'
		| 'timedatelong' = 'long',
	options: Parameters<typeof format>['2'] = defaultFormatOptions
) {
	if (!date) return ''

	let dateFormat: string

	switch (type) {
		case 'full':
			dateFormat = `dd/MM/yy HH:mm:ss`
			break
		case 'short':
			dateFormat = 'Pp'
			break
		case 'time':
			dateFormat = 'pp'
			break
		case 'timeshort':
			dateFormat = 'p'
			break
		case 'date':
			dateFormat = `P`
			break
		case 'date-nice':
			dateFormat = `PP`
			break
		case 'date-long':
			dateFormat = `PPPP`
			break
		case 'datetime':
			dateFormat = `dd/MM, HH:mm`
			break
		case 'datetimelong':
			dateFormat = `dd/MM/yy, HH:mm`
			break
		case 'timedate':
			dateFormat = 'HH:mm dd/MM/yy'
			break
		case 'timedatelong':
			dateFormat = "HH:mm 'on' dd/MM/yy"
			break
		case 'long':
		default:
			dateFormat = "PPP 'at' p"
			break
	}

	const dateParsed = typeof date === 'string' ? parseISO(date) : date
	return format(dateParsed, dateFormat, options)
}
