import { sendErrorReport } from './errorReporter'

/**
 * VERSION is helpful for support/debugging and to compare with the API to check
 * if it's not the matching version. This is set to a version number per release.
 */
export const VERSION = (import.meta.env.VITE_VERSION as string) || ''

/**
 * Fetches the version that will be available if the page reloaded. This is
 * helpful to check whether a deployment has occurred and the currently loaded
 * app version is old. It does not throw and returns an empty string if unable
 * to determine the version.
 */
export async function getRemoteVersion() {
	try {
		const response = await fetch('/version')

		if (!response.ok) {
			throw new Error(
				`Failed to determine remote version (status ${response.status})`
			)
		}

		const data = await response.json()

		if (!data || !data.version || typeof data.version !== 'string') {
			throw new Error(
				'Unable to determine remote version. No version returned from /version'
			)
		}

		return data.version as string
	} catch (err) {
		sendErrorReport(err)
		return ''
	}
}
