import clsx from 'clsx'
import { formatOrgCategory } from 'core/utils/org'
import { OrgCategory } from 'graphql/generated'
import { ComponentPropsWithoutRef } from 'react'
import Badge from '.'
import Tooltip from '../Tooltip'

type Props = Omit<ComponentPropsWithoutRef<typeof Badge>, 'children'> & {
	category?: OrgCategory | null
}

const OrgCategoryBadge = ({ category, className, ...props }: Props) => {
	if (!category) return null
	return (
		<Tooltip content={formatOrgCategory(category, 'long')}>
			<Badge {...props} className={clsx('w-[27px]', className)}>
				{formatOrgCategory(category, 'short')}
			</Badge>
		</Tooltip>
	)
}

export default OrgCategoryBadge
