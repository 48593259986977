import clsx from 'clsx'
import { ComponentProps } from 'react'
import Tooltip from '../Tooltip'

type Props = ComponentProps<'div'>

/**
 * AdvNotAvailable is a placeholder for when ADV isn't able to be displayed
 * It is wrapped in a tooltip to give more information to the user
 *
 * @example
 * <AdvNotAvailable />
 */

const AdvNotAvailable = ({ className }: Props) => {
	return (
		<Tooltip
			content="Average Daily Volume not available yet due to recent listing"
			delayDuration={100}
			className="w-40 text-left leading-normal"
		>
			<p className={clsx('font-normal uppercase text-gray', className)}>
				n/a
			</p>
		</Tooltip>
	)
}

export default AdvNotAvailable
