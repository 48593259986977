import { OrgCategory } from 'graphql/generated'

export function formatOrgCategory(
	category: OrgCategory,
	type: 'long' | 'short' = 'long'
) {
	let long = ''
	let short = ''

	switch (category) {
		case OrgCategory.LongOnly:
			long = 'Long-Only'
			short = 'LO'
			break
		case OrgCategory.HedgeFund:
			long = 'Hedge Fund'
			short = 'HF'
			break
		case OrgCategory.None:
			long = 'None'
			short = 'N'
			break
		default:
			return ''
	}

	return type === 'long' ? long : short
}
