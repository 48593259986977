import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { ComponentProps, FC } from 'react'

/**
 * Error messages help users easily locate an issue with a field and how to fix
 * it. Should be used in proximity of the respective `<Input />` component.
 *
 * @see `<Input />`
 *
 * @example
 * <Input value="110" />
 * <FormError>Must be a value between 0 and 100.</FormError>
 */
const FormError: FC<ComponentProps<'p'>> = ({ className, ...props }) => (
	<p
		role="alert"
		{...props}
		className={clsx(className, 'mt-2.5 text-sm text-magenta')}
	/>
)

export default FormError

/**
 * Augments the FormError component with a boolean flag that ensures it animates
 * in and out smoothly.
 *
 * @see `<FormError />`
 *
 * @example
 * <Input value="110" />
 * <FormErrorAnimated isVisible={isOutOfRange}>
 *   Must be a value between 0 and 100.
 * </FormErrorAnimated>
 */
export const FormErrorAnimated: FC<
	ComponentProps<typeof FormError> & {
		isVisible: boolean
	}
> = ({ isVisible, className, ...props }) => (
	<AnimatePresence>
		{!!isVisible && (
			<motion.div
				initial={{ height: 0, opacity: 0 }}
				animate={{ height: 'auto', opacity: 1 }}
				exit={{ height: 0, opacity: 0 }}
				className={clsx(className, 'overflow-hidden')}
			>
				<FormError {...props} />
			</motion.div>
		)}
	</AnimatePresence>
)
