import Heading from 'core/components/Heading'
import { CheckCircleAnimated } from 'core/components/Icon'
import Notice from 'core/components/Notice'

export default function Originated() {
	return (
		<Notice className="text-green">
			<CheckCircleAnimated size={48} />

			<Heading size={3} as="h2" className="mt-5">
				BookBuild Originated
			</Heading>

			<p className="mt-3">Your Bookbuild has been started</p>
		</Notice>
	)
}
