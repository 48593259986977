import { ComponentProps } from 'react'

/**
 * Displays small capitalised text to be used to label information
 *
 * @example
 * <Caption>Stock</Caption>
 * <Caption color="gray">Market</Caption>
 */
const Caption = (props: ComponentProps<'div'>) => (
	<div
		{...props}
		className={`text-3xs font-bold uppercase tracking-wide ${props.className}`}
	/>
)

export default Caption
