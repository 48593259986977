import { GraphQLError } from 'core/components/ErrorMessage'
import { CombinedError } from 'urql'

type Props = {
	error: CombinedError
}

const UserError = ({ error }: Props) => (
	<div className="flex flex-1 animate-fade-in items-center justify-center">
		<GraphQLError
			error={error}
			message="There is an issue starting the application"
		/>
	</div>
)

export default UserError
