import {
	ArrowRight,
	CheckCircleAnimated,
	Info,
	MinusCircle,
	RefreshCw,
} from 'core/components/Icon'
import { Logo } from 'core/components/Logo'
import Spinner from 'core/components/Spinner'
import { apiRequest } from 'core/utils/api'
import { sendErrorReport } from 'core/utils/errorReporter'
import { ActionStatus } from 'core/utils/types'
import { ComponentPropsWithoutRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const API_PATH = '/pricediscovery/respond'

enum Status {
	OK = 'OK',
	PreviousResponse = 'PreviousResponse',
	NotActive = 'NotActive',
	Error = 'Error',
}

type Data = {
	status: Status
}

const Content = ({
	children,
}: Pick<ComponentPropsWithoutRef<'div'>, 'children'>) => (
	<div className="m-auto grid animate-fade-in place-items-center gap-12 animate-duration-slow">
		<Logo className="animate-fade-in-slide-up animate-duration-slower" />

		<div className="m-auto flex max-h-[100vh] w-full min-w-[320px] max-w-lg animate-fade-in-scale-super-subtle flex-col place-items-center overflow-y-auto bg-blue-dark p-8 text-center shadow-subtle will-change-transform animate-duration-slower sm:p-11 lg:max-w-[618px] lg:p-14">
			{children}
		</div>
	</div>
)

export function PriceDiscoveryRespondWithToken() {
	const location = useLocation()
	const [requestStatus, setRequestStatus] = useState<ActionStatus>('initial')
	const [status, setStatus] = useState<Status>()

	useEffect(() => {
		const abortController = new AbortController()
		const query = new URLSearchParams(location.search)
		const token = query.get('token') || ''

		setRequestStatus('loading')

		apiRequest({ path: API_PATH, token, method: 'POST', abortController })
			.then(async (response) => {
				const d: Data = await response.json()

				if (!d || !d.hasOwnProperty('status')) {
					throw new Error(
						'Invalid data returned in Price Discovery respond with token flow'
					)
				}

				setRequestStatus('completed')
				setStatus(d.status)
			})
			.catch((err) => {
				setRequestStatus('failed')
				setStatus(Status.Error)
				sendErrorReport(err)
			})

		return () => abortController.abort()
	}, [location.search])

	if (requestStatus === 'loading' || !status) {
		return <Spinner />
	}

	switch (status) {
		case Status.OK:
			return (
				<Content>
					<CheckCircleAnimated
						size={32}
						className="stroke-[1.5] text-green"
					/>

					<h1 className="mt-6 text-2xl font-bold text-green">
						Feedback Submitted
					</h1>

					<p className="mt-3.5 text-green">
						Your <strong>&lsquo;Not interested&rsquo;</strong>{' '}
						response has been successfully recorded.
					</p>

					<p className="mt-16 text-gray-darkish">
						It&apos;s now safe to close the window.
					</p>

					<a
						href="/opportunities/today"
						className="mt-6 flex place-items-center gap-2 font-bold text-gray transition-all hover:text-gray-light active:scale-95 active:opacity-95"
					>
						Go to Price Discovery
						<ArrowRight size={16} className="stroke-[2.5]" />
					</a>
				</Content>
			)

		case Status.PreviousResponse:
			return (
				<Content>
					<Info
						size={32}
						className="stroke-[1.5] text-gray-light animate-icon"
					/>

					<h1 className="mt-6 text-2xl font-bold text-gray-light">
						Feedback Already Provided
					</h1>

					<p className="mt-3.5 max-w-[460px] text-gray">
						A response has already been recorded for this Price
						Discovery for your organisation.
					</p>

					<p className="mt-16 text-gray-darkish">
						It&apos;s now safe to close the window.
					</p>

					<a
						href="/opportunities/today"
						className="mt-6 flex place-items-center gap-2 font-bold text-gray transition-all hover:text-gray-light active:scale-95 active:opacity-95"
					>
						Go to Price Discovery
						<ArrowRight size={16} className="stroke-[2.5]" />
					</a>
				</Content>
			)

		case Status.NotActive:
			return (
				<Content>
					<MinusCircle
						size={32}
						className="stroke-[1.5] text-gray-light animate-icon"
					/>

					<h1 className="mt-6 text-2xl font-bold text-gray-light">
						Price Discovery Expired
					</h1>

					<p className="mt-3.5 text-gray">
						Feedback cannot be recorded as Price Discovery is no
						longer available.
					</p>

					<p className="mt-16 text-gray-darkish">
						It&apos;s now safe to close the window.
					</p>

					<a
						href="/opportunities/today"
						className="mt-6 flex place-items-center gap-2 font-bold text-gray transition-all hover:text-gray-light active:scale-95 active:opacity-95"
					>
						Log in to Appital
						<ArrowRight size={16} className="stroke-[2.5]" />
					</a>
				</Content>
			)

		case Status.Error:
			return (
				<Content>
					<Info
						size={32}
						className="stroke-[1.5] text-gray-light animate-icon"
					/>

					<h1 className="mt-6 text-2xl font-bold text-gray-light">
						Something Went Wrong
					</h1>

					<p className="mt-3.5 max-w-[460px] text-gray">
						We&apos;re having trouble loading this page due to a
						temporary issue.
					</p>

					<button
						className="mt-11 flex place-items-center gap-2 font-bold text-gray transition-all hover:text-gray-light active:scale-95 active:opacity-95"
						onClick={() => window.location.reload()}
					>
						Try Again
						<RefreshCw size={16} className="stroke-[2.5]" />
					</button>

					<p className="mt-10 text-gray-darkish">
						If the issue persists, contact us at{' '}
						<strong className="text-gray">
							support@appital.io
						</strong>
					</p>
				</Content>
			)
	}
}
