import { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export default function InfoIcon({ className }: Props) {
	return (
		<svg
			viewBox="0 0 2 8"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path d="M0.996 0C0.432 0 0 0.408 0 0.972C0 1.536 0.432 1.956 0.996 1.956C1.548 1.956 1.98 1.536 1.98 0.972C1.98 0.408 1.548 0 0.996 0ZM0.144 2.592V7.76H1.836V2.592H0.144Z" />
		</svg>
	)
}
