import { useAuth0 } from '@auth0/auth0-react'
import { reset as resetAnalytics } from 'analytics'
import Button from 'core/components/Button'
import ErrorMessage from 'core/components/ErrorMessage'
import Heading from 'core/components/Heading'
import { Lock, LogIn } from 'core/components/Icon'
import Spinner from 'core/components/Spinner'
import useDocumentTitle from 'core/hooks/useDocumentTitle'
import { useCallback, useEffect, useState } from 'react'

/**
 * useLogout returns a function that can be called to initiate a log out. It
 * takes an optional returnTo param which makes contain an allowed logout URL.
 */
export function useLogout() {
	const { logout } = useAuth0()
	return useCallback(
		(returnTo = window.location.origin) => {
			window.sessionStorage.removeItem('token')
			resetAnalytics()
			logout({ logoutParams: { returnTo } })
		},
		[logout]
	)
}

/** SignOutPage will automatically sign the user out on page load. This is
 * useful in case the users are stuck in a session and need to ensure they're
 * logged out. They just need to visit this page and it does the same as the
 * logout button. */
export function SignOutPage() {
	const logout = useLogout()
	useDocumentTitle('Signing out...')
	useEffect(logout)
	return (
		<div className="flex flex-1 animate-fade-in items-center justify-center">
			<ErrorMessage>
				<Spinner
					color="gray"
					size={36}
					delay={false}
					className="-mt-9"
				/>
				<Heading size={4} className="mt-7">
					Signing out...
				</Heading>
			</ErrorMessage>
		</div>
	)
}

/** SignedOutPage provides more context to the user as to why they were signed
 * out. This can be due to inactivity or other security reasons. It provides a
 * way to sign in again. */
export function SignedOutPage() {
	const { loginWithRedirect } = useAuth0()
	const [isLoading, setLoading] = useState(false)

	const url = new URL(window.location.toString())
	const returnTo = url.searchParams.get('returnTo') ?? undefined

	return (
		<ErrorMessage>
			<Lock size={48} />

			<Heading size={4} className="mt-5">
				You have been signed out
			</Heading>

			<div className="mt-3">
				Your organization&apos;s policy enforces automatic sign out
				after a period of inactivity.
			</div>

			<div className="mt-14">
				<Button
					size="large"
					before={LogIn}
					loading={isLoading}
					disabled={isLoading}
					onClick={() => {
						setLoading(true)
						loginWithRedirect({ appState: { returnTo } })
					}}
				>
					Sign in to Appital
				</Button>
			</div>
		</ErrorMessage>
	)
}
