import { parseNumber } from './number'

/** Grabs the first digit in a string */
function firstDigits(text: string): string {
	const match = text.match(new RegExp(/\d+(\.\d+)?/g))
	if (!match) return ''
	return match[0]
}

/** Sorts holding fuzzy amounts properly, e.g. >5%, 1-5%, 0-1% */
export function sortHoldingFuzzyAmounts(a: string, b: string) {
	const aDigits = firstDigits(a)
	const bDigits = firstDigits(b)

	let aNum = parseNumber(aDigits)
	let bNum = parseNumber(bDigits)

	if (isNaN(aNum) || isNaN(bNum)) return 0

	// handle 'greater than' and 'smaller than' prefixes by adding or subtracting
	// the smallest amount possible
	if (a.startsWith('<')) aNum -= aNum * Number.EPSILON
	if (a.startsWith('>')) aNum += aNum * Number.EPSILON
	if (b.startsWith('<')) bNum -= bNum * Number.EPSILON
	if (b.startsWith('>')) bNum += bNum * Number.EPSILON

	if (aNum > bNum) return -1
	if (aNum === bNum) return 0
	return 1
}
