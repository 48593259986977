import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import { CombinedError } from 'urql'
import Button from '../Button'
import ButtonLink from '../ButtonLink'
import Heading from '../Heading'
import { AlertCircle } from '../Icon'
import Spinner from '../Spinner'

type Props = ComponentProps<'div'>

/**
 * Useful for displaying component error states in a consistent style
 *
 * @example
 * // simple example
 * <ErrorMessage>You tried something and it failed. Boo!</ErrorMessage>
 *
 * // spice it up with an icon
 * <ErrorMessage>
 *   <AlertCircle size={32} />
 *   <span className="mt-5">
 *     You tried something and it failed. Boo!
 *   </span>
 * </ErrorMessage>
 */
const ErrorMessage: FC<Props> = ({ children, ...props }: Props) => (
	<div
		{...props}
		className={clsx(
			props.className,
			'flex flex-1 animate-fade-in flex-col items-center justify-center text-center text-gray animate-duration-slower'
		)}
	>
		{children ||
			'Something went wrong. Please try again or contact support.'}
	</div>
)

export default ErrorMessage

type GraphQLErrorProps = Props & {
	message?: string
	error?: CombinedError
}

export const GraphQLError: FC<GraphQLErrorProps> = ({
	error,
	message = 'Something went wrong',
	...props
}) => {
	const networkMessage = error?.networkError?.message || ''

	const noConnectivity = networkMessage
		.toLowerCase()
		.includes('failed to fetch')

	return (
		<ErrorMessage {...props}>
			{noConnectivity ? (
				<>
					<AlertCircle size={48} />

					<Heading size={4} className="mt-5">
						Unable to Connect
					</Heading>

					<div className="mt-10 max-w-xl">
						The application is not currently able to connect to the
						Appital platform.
					</div>
				</>
			) : error?.networkError ? (
				<>
					<Spinner color="gray" size={36} delay={false} />

					<Heading size={4} className="mt-7">
						Connecting...
					</Heading>

					<div className="mt-10">
						Trying to connect to the Appital service
					</div>
				</>
			) : (
				<>
					<AlertCircle size={48} />

					<Heading size={4} className="mt-5">
						Error
					</Heading>

					<div className="mt-10">{message}</div>
				</>
			)}

			<span className="mt-1 text-sm text-gray-dark">
				If the issue persists,
				{error?.networkError && ' try refreshing your browser or '}{' '}
				contact support@appital.io
			</span>

			<div className="mt-7 flex gap-5">
				<ButtonLink variant="bare" color="gray" to="/auth/signout">
					Sign Out
				</ButtonLink>

				<Button
					variant="bare"
					color="gray"
					onClick={() => window.location.reload()}
				>
					Reload
				</Button>
			</div>
		</ErrorMessage>
	)
}
