import clsx from 'clsx'
import { ComponentProps } from 'react'

type Props = Omit<ComponentProps<'input'>, 'type'> & {
	speed?: 'normal' | 'slow'
	color?: 'blue-darkish' | 'blue-dark'
}

const InputToggle = ({
	className,
	children,
	checked,
	disabled,
	speed = 'normal',
	color = 'blue-darkish',
	...props
}: Props) => (
	<label
		className={clsx(
			'group flex items-center gap-3 text-sm font-bold',
			disabled ? 'text-gray-dark' : 'cursor-pointer text-white',
			className
		)}
	>
		<input
			{...props}
			checked={checked}
			disabled={disabled}
			className="sr-only"
			type="checkbox"
		/>

		<span
			className={clsx(
				'flex h-[18px] w-[36px] flex-shrink-0 items-center rounded-full transition-all',
				color === 'blue-darkish' &&
					'bg-blue-darkish group-hover:bg-opacity-70',
				color === 'blue-dark' &&
					'bg-blue-dark group-hover:bg-opacity-70',
				checked && 'bg-blue-highlight',
				disabled && '!bg-gray-dark',
				speed === 'normal' && 'duration-normal',
				speed === 'slow' && 'duration-500'
			)}
		>
			<span
				className={clsx(
					'mx-1 h-[12px] w-[12px] transform-gpu rounded-full transition-all',
					checked && !disabled ? 'translate-x-4' : 'translate-x-0',
					speed === 'normal' && 'duration-normal',
					speed === 'slow' && 'duration-500',
					disabled ? 'bg-gray' : 'bg-white'
				)}
			/>
		</span>

		{children}
	</label>
)

export default InputToggle
