import ErrorMessage from 'core/components/ErrorMessage'
import Heading from 'core/components/Heading'
import Spinner from 'core/components/Spinner'
import useDocumentTitle from 'core/hooks/useDocumentTitle'

const SettingUp = () => {
	useDocumentTitle('Account being set up')
	return (
		<ErrorMessage>
			<Spinner
				color="gray"
				size={36}
				delay={false}
				className="flex-none"
			/>

			<Heading size={4} className="mt-7">
				Setting up your account...
			</Heading>

			<p className="mt-2">
				You&apos;ll receive an email when it&apos;s ready
			</p>
		</ErrorMessage>
	)
}

export default SettingUp
