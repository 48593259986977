import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import classes from './index.module.css'

/**
 * Inline placeholder for missing values, especially when using
 * the `<TextLabel />` component.
 *
 * @example
 * <Placeholder />
 *
 * <TextLabel label="Phone Number">
 *   {phoneNumber || <Placeholder />}
 * </TextLabel>
 */
const Placeholder: FC<ComponentProps<'span'>> = (props) => (
	<span {...props} className={clsx(props.className, classes.placeholder)} />
)

export default Placeholder
