import Button from 'core/components/Button'
import {
	AnchorHTMLAttributes,
	ComponentProps,
	ComponentPropsWithRef,
	FC,
	ReactNode,
} from 'react'
import { Link } from 'react-router-dom'

type Props = ReactRouterLinkProps &
	ComponentProps<typeof Button> & { children: ReactNode }

type ReactRouterLinkProps = Omit<
	ComponentPropsWithRef<typeof Link>,
	keyof AnchorHTMLAttributes<HTMLAnchorElement> | 'ref' | 'key'
>

/**
 * A Button that acts as a Link (React Router)
 *
 * @example
 * <Button
 *   to="/example"
 *   variant="bordered"
 *   color="green"
 *   title="View example"
 *   before={<ChevronRight />}
 * >
 *   Example
 * </Button>
 */
const ButtonLink: FC<Props> = ({
	to,
	replace,
	className,
	children,
	...props
}) => {
	return (
		<Link to={to} replace={replace} className={className}>
			<Button {...props}>{children}</Button>
		</Link>
	)
}

export default ButtonLink
