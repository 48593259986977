import clsx from 'clsx'
import InfoIcon from 'core/components/Icon/InfoIcon'
import Tooltip from 'core/components/Tooltip'
import { LayoutGroup } from 'framer-motion'
import { useState } from 'react'
import { DistributionPreviewReach, SelectableOrg } from '.'
import DistributionExclusions from './Exclusions'
import DistributionInterestOption from './InterestOption'

type Props = {
	bookbuildId: string
	reach: DistributionPreviewReach
	includePriceDiscovery: boolean
	includeLiveWatchlists: boolean
	includePassiveInterest: boolean
	excludedOrgIds: string[]
	selectableOrgs: SelectableOrg[]
	onLiveWatchlistsChecked: (checked: boolean) => void
	onPassiveInterestChecked: (checked: boolean) => void
	onPriceDiscoveryChecked: (checked: boolean) => void
	onExcludedOrgIdsChanged: (excludedOrgIds: string[]) => void
}

const DistributionInterestSection = ({
	bookbuildId,
	reach,
	includePriceDiscovery,
	includeLiveWatchlists,
	includePassiveInterest,
	excludedOrgIds,
	selectableOrgs,
	onLiveWatchlistsChecked,
	onPassiveInterestChecked,
	onExcludedOrgIdsChanged,
	onPriceDiscoveryChecked,
}: Props) => {
	const [isExclusionsExpanded, setExclusionsExpanded] = useState(false)

	let potentialFirms = 0

	if (includePriceDiscovery) {
		potentialFirms += reach.priceDiscoveryFirms
	}

	if (includeLiveWatchlists) {
		potentialFirms += reach.liveWatchlistsFirms
	}

	if (includePassiveInterest) {
		potentialFirms += reach.passiveInterestFirms
	}

	const showPriceDiscovery = reach.priceDiscoveryFirms > 0

	const interestSelected = showPriceDiscovery
		? includePriceDiscovery ||
			includeLiveWatchlists ||
			includePassiveInterest
		: includeLiveWatchlists || includePassiveInterest

	if (!interestSelected) {
		potentialFirms =
			reach.priceDiscoveryFirms +
			reach.liveWatchlistsFirms +
			reach.passiveInterestFirms
	}

	return (
		<div className="relative z-0 flex flex-col gap-3 overflow-hidden rounded-xl bg-blue-darker p-6">
			<header className="mb-1 flex justify-between">
				<div>
					<h2 className="mb-1 text-lg font-bold leading-tight">
						Interest based
					</h2>

					<p className="text-sm leading-snug text-gray">
						Target firms by interest
					</p>
				</div>

				<div
					className={clsx(
						'flex flex-col text-right transition-colors duration-normal',
						interestSelected ? 'text-yellow' : 'text-gray'
					)}
				>
					<span className="mb-1 text-lg font-bold leading-tight">
						{potentialFirms}
					</span>

					<Tooltip
						content="To protect anonymity, the number of targeted firms might be lower"
						side="top"
						align="center"
						delayDuration={100}
						className="max-w-[250px] text-center text-xs leading-snug"
					>
						<span className="text-sm leading-snug">
							{' '}
							potential {potentialFirms === 1 ? 'firm' : 'firms'}
						</span>
					</Tooltip>
				</div>
			</header>

			<LayoutGroup>
				{showPriceDiscovery && (
					<DistributionInterestOption
						name={
							<span className="inline-flex items-center gap-2 whitespace-nowrap leading-none">
								<span>Price Discovery</span>

								<Tooltip
									content="Start a BookBuild with a minimum of 1 firm, when targeting via Price Discovery."
									side="top"
									align="center"
									delayDuration={100}
									color="blue-darker"
									className={clsx(
										'max-w-[250px] text-center leading-snug shadow-[3px_0px_20px_5px_rgba(0,0,0,0.60)]'
									)}
								>
									<span className="flex h-[12px] w-[12px] items-center justify-center rounded-full bg-gray">
										<InfoIcon className="h-[8px] w-[2px] fill-blue-darkish" />
									</span>
								</Tooltip>
							</span>
						}
						description="Target firms that submitted interest"
						firmCount={reach.priceDiscoveryFirms}
						checked={includePriceDiscovery}
						collapsed={isExclusionsExpanded}
						compact
						data-testid="distribution-method-price-discovery"
						onChecked={onPriceDiscoveryChecked}
					/>
				)}

				<DistributionInterestOption
					name="Live Watchlist"
					description="Target liquidity at mid-pricing"
					firmCount={reach.liveWatchlistsFirms || 0}
					checked={
						includeLiveWatchlists && reach.liveWatchlistsFirms > 0
					}
					disabled={reach.liveWatchlistsFirms === 0}
					collapsed={isExclusionsExpanded}
					compact={showPriceDiscovery}
					data-testid="distribution-method-live-watchlist"
					onChecked={onLiveWatchlistsChecked}
				/>

				<DistributionInterestOption
					name="Passive Interest"
					description="Source liquidity at discount or premium"
					firmCount={reach.passiveInterestFirms || 0}
					checked={
						includePassiveInterest && reach.passiveInterestFirms > 0
					}
					disabled={reach.passiveInterestFirms === 0}
					collapsed={isExclusionsExpanded}
					compact={showPriceDiscovery}
					data-testid="distribution-method-passive-interest"
					onChecked={onPassiveInterestChecked}
				/>

				<DistributionExclusions
					bookbuildId={bookbuildId}
					excludedOrgIds={excludedOrgIds}
					selectableOrgs={selectableOrgs}
					expanded={isExclusionsExpanded}
					compact={showPriceDiscovery}
					onExpandChange={setExclusionsExpanded}
					onExcludedOrgIdsChanged={onExcludedOrgIdsChanged}
				/>
			</LayoutGroup>
		</div>
	)
}

export default DistributionInterestSection
