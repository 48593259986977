import { Types } from '@amplitude/analytics-browser'
import { BaseTransport } from '@amplitude/analytics-core'

/**
 * Plugin to replace the default transport provider with our custom
 * implementation which adds the Authorization header.
 */
export class CustomTransportPlugin implements Types.DestinationPlugin {
	public type = 'destination' as const
	public name = 'custom-transport' as const

	getToken: () => Promise<string>
	disabled: boolean

	constructor(params: {
		getToken: () => Promise<string>
		disabled?: boolean
	}) {
		this.getToken = params.getToken
		this.disabled = params.disabled ?? false
	}

	async setup(config: Types.Config) {
		// Slightly hacky way to replace the transport provider but they removed
		// the option to do it via config in v2.
		config.transportProvider = new AuthenticatedTransport(
			this.getToken,
			this.disabled
		)
	}

	async execute(event: Types.Event) {
		return {
			code: 200,
			event: event,
			message: 'OK',
		}
	}
}

/**
 * Custom transport that adds the Authorization header via an async getter
 * function for the bearer token.
 */
export class AuthenticatedTransport extends BaseTransport {
	getToken: () => Promise<string>
	disabled: boolean

	constructor(getToken: () => Promise<string>, disabled = false) {
		super()
		this.getToken = getToken
		this.disabled = disabled
	}

	async send(
		serverUrl: string,
		payload: Types.Payload
	): Promise<Types.Response | null> {
		if (this.disabled) {
			return this.buildResponse({
				code: 200,
				events_ingested: payload.events.length,
			})
		}

		const token = await this.getToken()

		const response = await fetch(serverUrl, {
			method: 'POST',
			headers: {
				Authorization: token ? `Bearer ${token}` : '',
				'Content-Type': 'application/json',
				Accept: '*/*',
			},
			body: JSON.stringify(payload),
		})

		const responsePayload: Types.SuccessResponse = await response.json()

		return this.buildResponse(responsePayload)
	}
}
