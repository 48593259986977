import clsx from 'clsx'
import { ComponentProps, forwardRef } from 'react'
import classes from './index.module.css'

type Props = ComponentProps<'input'> & {
	color?: InputColor
}

export type InputColor = (typeof colors)[number]

export const colors = [
	'yellow',
	'magenta',
	'green',
	'gray',
	'gray-dark',
	'gray-light',
	'white',
] as const

const Input = forwardRef<HTMLInputElement, Props>(function Input(
	{
		color = 'yellow',
		type = 'text',
		placeholder = ' ', // This empty placeholder helps ensure we can style "dirty" or "filled" inputs with colored border
		className,
		...props
	},
	ref
) {
	return (
		<input
			{...props}
			ref={ref}
			type={type}
			placeholder={placeholder}
			className={clsx(
				color === 'yellow' && 'text-yellow selection:bg-yellow',
				color === 'magenta' && 'text-magenta selection:bg-magenta',
				color === 'green' && 'text-green selection:bg-green',
				color === 'gray' && 'text-gray selection:bg-gray',
				color === 'white' && 'text-white selection:bg-white',
				color === 'gray-dark' &&
					'text-gray-dark selection:bg-gray-dark',
				color === 'gray-light' &&
					'text-gray-light selection:bg-gray-light',
				classes.input,
				className
			)}
		/>
	)
})

export default Input
