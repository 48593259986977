import { useAuth0 } from '@auth0/auth0-react'
import { sendErrorReport } from 'core/utils/errorReporter'
import { useCallback } from 'react'
import { SESSIONSTORAGE_TOKEN_ENABLED } from './config'

export function useAuthToken() {
	const { getAccessTokenSilently } = useAuth0()

	const getToken = useCallback(async () => {
		const localToken = window.sessionStorage.getItem('token')

		if (localToken && SESSIONSTORAGE_TOKEN_ENABLED) {
			return localToken
		}

		let auth0Token

		try {
			auth0Token = await getAccessTokenSilently()
		} catch (err) {
			sendErrorReport(err)
			return ''
		}

		return auth0Token
	}, [getAccessTokenSilently])

	return getToken
}
