import { useCallback } from 'react'
import { AnyVariables, Operation, useClient } from 'urql'

/**
 * Returns an async function which refetches the data for the given useQuery
 * hook's operation. This is very helpful if you need to imperatively refetch
 * data via a promise. It will automatically update the hook's data variable and
 * takes into account any variables or context that the hook has been given.
 *
 * @example
 * const [{ data, operation }] = useSomethingQuery()
 *
 * const refetch = useQueryPromise(operation)
 *
 * const myThing = useCallback(async () => {
 *  doSomething()
 *  await refetch() // the data variable will update automatically
 *  doSomethingAfter()
 * })
 */
export function useQueryPromise<
	Data,
	Variables extends AnyVariables = AnyVariables,
>(operation?: Operation<Data, Variables>) {
	const client = useClient()

	const refetch = useCallback(async () => {
		if (!operation) return
		return client
			.query(operation.query, operation.variables, {
				...(operation.context || {}),
				...{ requestPolicy: 'network-only' },
			})
			.toPromise()
	}, [client, operation])

	return refetch
}
