import {
	Auth0Provider,
	AuthorizationParams,
	useAuth0,
} from '@auth0/auth0-react'
import ErrorMessage from 'core/components/ErrorMessage'
import Spinner from 'core/components/Spinner'
import { ReactNode } from 'react'
import { AUDIENCE, CLIENTID, DOMAIN, SCOPE } from './config'

const authParams: AuthorizationParams = {
	redirect_uri: window.location.origin,
	audience: AUDIENCE,
	scope: SCOPE,
}

export function AuthenticationProvider({ children }: { children: ReactNode }) {
	return (
		<Auth0Provider
			domain={DOMAIN}
			clientId={CLIENTID}
			authorizationParams={authParams}
			useRefreshTokens={true}
			leeway={120}
		>
			<WhenReady>{children}</WhenReady>
		</Auth0Provider>
	)
}

function WhenReady({ children }: { children: ReactNode }) {
	const { isLoading, error } = useAuth0()

	if (isLoading) {
		return <Spinner />
	}

	if (error) {
		return <ErrorMessage>{error.message}</ErrorMessage>
	}

	return <>{children}</>
}
