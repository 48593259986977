import clsx from 'clsx'
import { Side } from 'graphql/generated'
import { ComponentProps } from 'react'

type Props = Omit<ComponentProps<'span'>, 'children'> & {
	side: Side
	inactive?: boolean
}

const SideLabel = ({ side, inactive, className, ...props }: Props) => (
	<span
		{...props}
		className={clsx(
			'flex h-[1.6875rem] w-11 items-center justify-center rounded-sm border border-transparent px-2 pb-1 pt-[3px] text-xs font-bold uppercase leading-none text-white transition-colors',
			side === Side.Buy ? 'bg-green bg-opacity-60' : 'bg-magenta',
			inactive && [
				'!bg-transparent !text-gray',
				side === Side.Buy
					? '!border-green !border-opacity-60'
					: '!border-magenta',
			],
			className
		)}
	>
		{side}
	</span>
)

export default SideLabel
