import { Types } from '@amplitude/analytics-browser'
import { API_ENDPOINT } from 'core/utils/api'
import {
	Ampli,
	ampli,
	AppOpenedProperties,
	IdentifyProperties,
} from './generated'
import { CustomTransportPlugin } from './transport'

const ENABLED = JSON.parse(import.meta.env.VITE_ANALYTICS_ENABLED)
const DEBUG = ENABLED && JSON.parse(import.meta.env.VITE_ANALYTICS_DEBUG)

/** Ampli generated library event methods */
type Events = Omit<Ampli, 'identify' | 'load' | 'client' | 'track' | 'isLoaded'>

/** Type-safe analytics tracking events */
export const events: Events = ampli

type InitOptions = {
	userId: string
	sessionId: number
	userProperties?: IdentifyProperties
	getToken: () => Promise<string>
}

/** Initialises the analytics client */
export function init(options: InitOptions): boolean {
	// If already loaded just update the custom session ID
	if (ampli.isLoaded) {
		ampli.client.setSessionId(options.sessionId)
		return true
	}

	// Load the client
	ampli.load({
		disabled: !ENABLED,
		client: {
			/**
			 * Redacted API Key.
			 *
			 * Our backend will replace with the real API key so please DO NOT
			 * PASTE a real API key here - there's no need.
			 */
			apiKey: 'redacted',

			// Client configuration
			configuration: {
				// initialise with the correct session ID
				sessionId: options.sessionId,

				// proxied via our API so that it's authed
				serverUrl: API_ENDPOINT + '/analytics',

				// EU data residency
				serverZone: 'EU',

				// default tracking off as we track sessions ourselves
				defaultTracking: false,

				// log level
				logLevel: DEBUG ? Types.LogLevel.Verbose : Types.LogLevel.None,
			},
		},
	})

	// we use a custom transport to allow enriching with the API JWT
	ampli.client.add(
		new CustomTransportPlugin({
			getToken: options.getToken,
			disabled: !ENABLED,
		})
	)

	// Attach the user ID and properties
	ampli.identify(options.userId, options.userProperties, {
		session_id: options.sessionId,
	})

	// Identify has an issue where it resets the session ID to Date.now() so we
	// need to set it here again.
	ampli.client.setSessionId(options.sessionId)

	// Track the app opened event
	trackAppOpened()

	return true
}

/**
 * Shortcut to anonymize users after they log out
 */
export function reset() {
	if (ampli && ampli.isLoaded) {
		ampli.client.reset()
	}
}

/**
 * Helper to simplify tracking pageviews
 */
export function trackPageView() {
	ampli.pageViewed(getPageProperties())
}

/**
 * Helper to simplify tracking pageviews
 */
export function trackAppOpened() {
	const appOpenedProps: AppOpenedProperties = getPageProperties()

	const url = new URL(window.location.toString())

	const utmMedium = url.searchParams.get('utm_medium')
	const utmSource = url.searchParams.get('utm_source')
	const utmCampaign = url.searchParams.get('utm_campaign')

	if (utmMedium) appOpenedProps['UTM Medium'] = utmMedium
	if (utmSource) appOpenedProps['UTM Source'] = utmSource
	if (utmCampaign) appOpenedProps['UTM Campaign'] = utmCampaign

	ampli.appOpened(appOpenedProps)
}

/**
 * Helper to safely track page properties
 */
export function getPageProperties() {
	return {
		'Page Domain': window.location.hostname,
		'Page Location': window.location.href.replace(/\?.*token=.*$/gi, ''), // remove search string if it contains a token
		'Page Path': window.location.pathname,
		'Page URL': window.location.origin + window.location.pathname,
	}
}
