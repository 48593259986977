import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Spinner from 'core/components/Spinner'
import React, { ComponentProps, FC, Fragment, forwardRef } from 'react'
import Button from '../Button'
import { X } from '../Icon'

type Props = ComponentProps<'div'> & {
	isOpen?: boolean
	initialFocus?: React.MutableRefObject<HTMLElement | null>
	isLoading?: boolean
	onDismiss?: () => void
}

export const DialogOverlay = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
	function DialogOverlay(props, ref) {
		return (
			<div
				{...props}
				ref={ref}
				className={clsx(
					props.className,
					'fixed inset-0 z-1 animate-fade-in bg-blue-darkest bg-opacity-90 backdrop-blur-sm animate-duration-slow'
				)}
			/>
		)
	}
)

export const DialogPanel = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
	function DialogPanel(props, ref) {
		return (
			<HeadlessDialog.Panel
				{...props}
				ref={ref}
				className={clsx(
					'relative z-2 max-h-[100vh] w-full min-w-[320px] max-w-md overflow-y-auto bg-blue-dark p-6 shadow-subtle',
					'sm:p-8 lg:max-w-lg lg:p-10',
					props.className
				)}
			/>
		)
	}
)

export const DialogCloseButton: FC<ComponentProps<typeof Button>> = (props) => (
	<Button
		variant="bare"
		color="gray"
		size="tiny"
		title="Close"
		{...props}
		className={clsx(
			props.className,
			'!absolute right-0 top-0 z-3 mr-4 mt-4 aspect-square !h-auto w-4 !p-0'
		)}
	>
		<X className="h-full w-full" />
	</Button>
)

/**
 * Dialog informs users about a task and can contain critical information, require decisions, or involve multiple tasks.
 *
 * @example
 * <Dialog ariaLabel="Example dialog">Hello</Dialog>
 */
const Dialog = forwardRef<HTMLDivElement, Props>(function Dialog(
	{
		isOpen = true,
		onDismiss = () => {},
		initialFocus,
		isLoading,
		children,
		'aria-label': ariaLabel,
		...props
	},
	ref
) {
	return (
		<Transition
			show={isOpen}
			as={Fragment}
			enter="transition-opacity duration-normal"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-normal"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<HeadlessDialog
				onClose={onDismiss}
				initialFocus={initialFocus}
				className="fixed inset-0 z-99 flex items-center justify-center px-7"
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<DialogOverlay
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}}
					/>
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="transition-transform duration-normal"
					enterFrom="scale-95 opacity-0"
					enterTo="scale-100 opacity-100"
					leave="transition-transform duration-normal"
					leaveFrom="scale-100"
					leaveTo="scale-95"
				>
					<DialogPanel aria-label={ariaLabel} {...props} ref={ref}>
						{isLoading && (
							<Spinner
								delay={false}
								fadeIn={false}
								className="absolute inset-0 z-2 bg-blue-dark"
							/>
						)}
						{children}
					</DialogPanel>
				</Transition.Child>
			</HeadlessDialog>
		</Transition>
	)
})

export default Dialog
