import clsx from 'clsx'
import { useCombobox, UseComboboxStateChange } from 'downshift'
import { motion } from 'framer-motion'
import { ComponentProps, ReactNode, useCallback, useState } from 'react'
import { Search, X } from '../Icon'
import Input from '../Input'
import InputAdorned from '../InputAdorned'
import AutocompleteList from './AutocompleteList'
import AutocompleteListItem from './AutocompleteListItem'

type Props<T extends Item> = {
	id?: string
	items: T[]
	selected: T | null
	isLoading?: boolean
	inputProps?: ComponentProps<typeof Input>
	inputBefore?: ReactNode
	inputWidth?: string
	inputLabel?: string
	menuProps?: ComponentProps<typeof motion.ul>
	menuWidth?: string
	menuMaxHeight?: string
	className?: string
	menuItemProps?: ComponentProps<typeof motion.li>
	renderMenuItem?: (item: T, index: number, highlighted: boolean) => ReactNode
	onSearch: (query: string) => void
	onSelect: (item: T | null) => void
	onClear: () => void
}

export type Item = {
	id: string
	label: string
}

export type SelectedItem = Item | null

type Changes<T extends Item> = UseComboboxStateChange<T>

const ChangeTypes = useCombobox.stateChangeTypes

/**
 * Autocomplete is a normal text input enhanced by a panel of suggested options.
 *
 * @example
 * <Autocomplete
 *   items={items}
 *   selected={selected}
 *   onSearch={handleSearch}
 *   onSelect={handleSelect}
 *   onClear={handleClear}
 * />
 */
export default function Autocomplete<T extends Item>({
	id,
	items,
	selected,
	isLoading,
	inputProps,
	inputBefore = <Search className="relative top-[1px] !h-4 !w-4" />,
	inputWidth = '350px',
	inputLabel = 'Search',
	menuProps,
	menuWidth = '350px',
	menuMaxHeight = '300px',
	menuItemProps,
	className,
	renderMenuItem,
	onSearch,
	onSelect,
	onClear,
}: Props<T>) {
	const [inputValue, setInputValue] = useState('')

	const handleInputValueChange = useCallback(
		(changes: Changes<T>) => {
			switch (changes.type) {
				case ChangeTypes.InputKeyDownEnter:
				case ChangeTypes.ItemClick:
					onSearch('')
					setInputValue('')
					break

				case ChangeTypes.ControlledPropUpdatedSelectedItem:
					setInputValue(changes.inputValue || '')
					break

				default:
					setInputValue(changes.inputValue || '')
					onSearch(changes.inputValue || '')
			}
		},
		[onSearch]
	)

	const handleSelectedItemChange = useCallback(
		(changes: Changes<T>) => {
			onSelect(changes.selectedItem || null)
			setInputValue('')
		},
		[onSelect]
	)

	const {
		getMenuProps,
		getInputProps,
		getItemProps,
		highlightedIndex,
		reset,
	} = useCombobox<T>({
		items,
		selectedItem: selected,
		inputValue,
		itemToString: (item) => item?.label || '',
		onInputValueChange: handleInputValueChange,
		onSelectedItemChange: handleSelectedItemChange,
	})

	const handleClear = useCallback(() => {
		reset()
		onClear()
	}, [onClear, reset])

	const showMenu = !isLoading && items.length >= 1

	return (
		<div className={clsx('relative z-2', className, showMenu && '!z-3')}>
			<div style={{ width: inputWidth }}>
				<InputAdorned
					loading={isLoading}
					before={inputBefore}
					after={
						!isLoading &&
						(!!inputValue || !!selected) && (
							<X
								className="!h-4 !w-4 opacity-50 hover:cursor-pointer hover:text-yellow hover:opacity-100"
								onClick={handleClear}
							/>
						)
					}
					color={inputProps?.color}
					style={{ width: inputWidth }}
				>
					<span className="sr-only">{inputLabel}</span>
					<Input
						{...inputProps}
						{...getInputProps()}
						id={id}
						aria-labelledby={id}
						type="search"
						autoComplete="off"
						className={clsx(
							'leading-[21px]',
							inputProps?.className
						)}
					/>
				</InputAdorned>
			</div>

			<AutocompleteList
				{...menuProps}
				{...getMenuProps()}
				isOpen={showMenu}
				style={{
					width: menuWidth,
					maxHeight: menuMaxHeight,
					borderTopRightRadius: menuWidth === inputWidth ? 0 : 14,
					...(menuProps?.style || {}),
				}}
			>
				{items.map((item, index) => (
					<AutocompleteListItem
						key={item.id}
						{...menuItemProps}
						{...getItemProps({ item, index })}
					>
						{renderMenuItem
							? renderMenuItem(
									item,
									index,
									highlightedIndex === index
								)
							: item.label}
					</AutocompleteListItem>
				))}
			</AutocompleteList>
		</div>
	)
}
