import ErrorMessage from 'core/components/ErrorMessage'
import { AlertCircle } from 'core/components/Icon'
import { DataStatus } from '.'

type Props = {
	status?: DataStatus
}

export default function Invalid({ status }: Props) {
	return (
		<ErrorMessage>
			<AlertCircle
				size={48}
				className="animate-fade-in-scale stroke-[1.5] animate-duration-slow"
			/>

			{!status && (
				<p className="mt-5">
					Something went wrong. Please try again or contact
					<a href="mailto:support@appital.io">
						&nbsp;support@appital.io
					</a>
				</p>
			)}

			{status && (
				<p className="mt-5 capitalize">{`${status} Bookbuild`}</p>
			)}
		</ErrorMessage>
	)
}
