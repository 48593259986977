import { events } from 'analytics'
import clsx from 'clsx'
import Button from 'core/components/Button'
import { X } from 'core/components/Icon'
import InputToggle from 'core/components/InputToggle'
import Tooltip from 'core/components/Tooltip'
import { sortHoldingFuzzyAmounts } from 'core/utils/holdings'
import uniq from 'lodash.uniq'
import { ComponentPropsWithoutRef, useCallback, useMemo } from 'react'
import { SelectableOrg } from '.'
import ManualOrg from './ManualOrg'
import { distributionGradientBorder } from './gradientBorder'

type Props = ComponentPropsWithoutRef<'section'> & {
	label?: string
	selectableOrgs: SelectableOrg[]
	selectedOrgIds: string[]
	excludedOrgIds: string[]
	trackingBookbuildId?: string
	trackingPriceDiscovery?: boolean
	showGradientBorder?: boolean
	onSelected: (orgId: string, selected: boolean) => void
	onMultipleSelected: (orgIds: string[]) => void
	onSelectNone: () => void
}

export type OrgGroupItem = SelectableOrg & {
	checked: boolean
	excluded?: boolean
}

type OrgGroup = {
	label: string
	orgs: OrgGroupItem[]
}

const DistributionManualSection = ({
	label = 'Manual',
	trackingBookbuildId,
	trackingPriceDiscovery,
	selectableOrgs,
	selectedOrgIds,
	excludedOrgIds,
	showGradientBorder = true,
	onSelected,
	onMultipleSelected,
	onSelectNone,
	className,
	...props
}: Props) => {
	const orgs: OrgGroupItem[] = useMemo(
		() =>
			selectableOrgs.map((org) => ({
				...org,
				checked: selectedOrgIds.includes(org.id),
				excluded: excludedOrgIds.includes(org.id),
			})),
		[excludedOrgIds, selectableOrgs, selectedOrgIds]
	)

	// Just the selected org IDs that are not excluded (helpful for counts). We
	// keep all selected org IDs so that we can enabled better UX when selecting
	// all holders and such. The user can add/remove exclusions without losing
	// the intended selection under the hood.
	const actualSelectedOrgIds = useMemo(
		() => selectedOrgIds.filter((id) => !excludedOrgIds.includes(id)),
		[excludedOrgIds, selectedOrgIds]
	)

	// Just the orgs that have holdings
	const holderOrgs = useMemo(
		() => orgs.filter((org) => !!org.fuzzyHoldingAmount), // orgs that have a holding amount
		[orgs]
	)

	// Just the holder org IDs
	const holderOrgIds = useMemo(
		() => holderOrgs.map((org) => org.id),
		[holderOrgs]
	)

	// holders without exclusions
	const holdersWithoutExclusions = useMemo(
		() => holderOrgIds.filter((id) => !excludedOrgIds.includes(id)),
		[excludedOrgIds, holderOrgIds]
	)

	// Just the holder org IDs that are currently selected (checked)
	const selectedHolderOrgIds = useMemo(
		() => selectedOrgIds.filter((id) => holderOrgIds.includes(id)),
		[holderOrgIds, selectedOrgIds]
	)

	// Boolean that states whether all the holder org IDs are part of the
	// selected group of org IDs
	const allHoldersSelected =
		!!holderOrgIds.length &&
		holderOrgIds.length === selectedHolderOrgIds.length

	// An array of holding "brackets", reverse ordered alphabetically so that
	// the highest number comes first. We end up with [>5%, 1-5%, 0-1%]
	const holdingBrackets = useMemo(
		() =>
			uniq(
				holderOrgs
					.map((org) => org.fuzzyHoldingAmount as string) // ['0-1%', '1-5%'] and so on
					.sort(sortHoldingFuzzyAmounts)
			),
		[holderOrgs]
	)

	// Just the orgs that have NO holdings
	const nonHolderOrgs = useMemo(
		() =>
			orgs
				.filter((org) => !org.fuzzyHoldingAmount) // orgs that don't have a holding amount
				.sort((a, b) => a.name.localeCompare(b.name)), // order alphabetically A-Z
		[orgs]
	)

	// Construct the bracket groups in the way they'll be organized in the DOM
	const bracketGroups: OrgGroup[] = useMemo(
		() => [
			// holders
			...holdingBrackets.map((bracket) => ({
				label: bracket,
				orgs: holderOrgs.filter(
					(holder) => holder.fuzzyHoldingAmount === bracket
				),
			})),

			// non-holders
			{
				label: 'Non-holders',
				orgs: nonHolderOrgs,
			},
		],
		[holderOrgs, holdingBrackets, nonHolderOrgs]
	)

	const onHandleManualFirmSelection = useCallback(
		(id: string, selected: boolean) => {
			onSelected(id, selected)

			let bracket

			// Check whether selected/deselected firm is within holding orgs
			const holder = holderOrgs.find((org) => org.id === id)

			// Holders
			if (holder) {
				bracket = holder.fuzzyHoldingAmount
			} else {
				bracket = 'Non holder'
			}

			if (selected) {
				events.distributionManualFirmAdded({
					'BookBuild ID': trackingBookbuildId || '',
					'Is Price Discovery': trackingPriceDiscovery || false,
					'Holding Bucket': bracket,
				})
			} else {
				events.distributionManualFirmRemoved({
					'BookBuild ID': trackingBookbuildId || '',
					'Is Price Discovery': trackingPriceDiscovery || false,
					'Holding Bucket': bracket,
				})
			}
		},
		[holderOrgs, onSelected, trackingBookbuildId, trackingPriceDiscovery]
	)

	const onSelectAllHolders = useCallback(() => {
		onMultipleSelected(uniq([...selectedOrgIds, ...holderOrgIds]))

		events.distributionManualAllHoldersIncluded({
			'BookBuild ID': trackingBookbuildId || '',
			'Is Price Discovery': trackingPriceDiscovery || false,
			'Item Count': holdersWithoutExclusions.length,
		})
	}, [
		holderOrgIds,
		holdersWithoutExclusions.length,
		onMultipleSelected,
		selectedOrgIds,
		trackingBookbuildId,
		trackingPriceDiscovery,
	])

	const holdersWithoutExclusionsSinglePlural =
		holdersWithoutExclusions.length === 1 ? 'holder' : 'holders'

	return (
		<section
			data-testid="distribution-method-manual"
			{...props}
			className={clsx(
				'px-5 pb-0 pt-6',
				className,
				showGradientBorder
					? distributionGradientBorder({
							bgColor: 'blue-darker',
							textColor: 'white',
							active: !!actualSelectedOrgIds.length,
						})
					: 'bg-blue-darker'
			)}
		>
			<header className="mx-1 -mt-0.5 mb-2 flex justify-between">
				<div>
					<h2 className="mb-1 text-lg font-bold leading-tight">
						{label}
					</h2>

					<p className="text-sm leading-tight text-gray">
						Select firms to target directly
					</p>
				</div>

				<div
					className={clsx(
						'flex flex-col text-right transition-colors duration-normal',
						!!actualSelectedOrgIds.length
							? 'text-yellow'
							: 'text-gray'
					)}
				>
					<span className="mb-1 text-lg font-bold leading-tight">
						{actualSelectedOrgIds.length || selectableOrgs.length}
					</span>

					<span className="text-sm leading-tight">
						{actualSelectedOrgIds.length === 1 ||
						selectableOrgs.length === 1
							? 'firm'
							: 'firms'}
					</span>
				</div>
			</header>

			<div className="mx-1 mb-0.5 flex items-center justify-between">
				<Tooltip
					content={
						!holdersWithoutExclusions.length
							? 'No holders available'
							: 'Based on Refinitiv data'
					}
					side="top"
					align="center"
					delayDuration={100}
				>
					<span>
						<InputToggle
							data-testid="include-all-holders"
							checked={allHoldersSelected}
							disabled={!holdersWithoutExclusions.length}
							onChange={(evt) =>
								evt.target.checked
									? onSelectAllHolders()
									: onMultipleSelected(
											selectedOrgIds.filter(
												(id) =>
													!holderOrgIds.includes(id)
											)
										)
							}
						>
							{!holdersWithoutExclusions.length
								? 'Include holders'
								: `Include ${holdersWithoutExclusions.length} ${holdersWithoutExclusionsSinglePlural}`}
						</InputToggle>
					</span>
				</Tooltip>

				<Button
					before={X}
					variant="bare"
					color="gray"
					size="small"
					className="!gap-1 font-bold disabled:!text-gray-dark"
					disabled={!selectedOrgIds.length}
					onClick={onSelectNone}
				>
					Clear
				</Button>
			</div>

			<div
				className={clsx(
					'relative max-h-[473px] overflow-y-auto overflow-x-hidden scrollbar-hide',
					// before
					'before:absolute before:-left-1 before:-right-1 before:-top-0.5 before:z-2 before:h-5 before:bg-gradient-to-b before:from-blue-darker before:to-transparent before:content-[""]',
					// after
					'after:absolute after:-bottom-0.5 after:-left-1 after:-right-1 after:z-2 after:h-5 after:rounded-xl after:bg-gradient-to-b after:from-transparent after:to-blue-darker after:content-[""]'
				)}
			>
				<div className="relative max-h-[473px] overflow-y-auto overflow-x-hidden py-2 scrollbar-hide">
					<h4 className="absolute right-0 mx-1 text-xs font-bold uppercase text-gray">
						Type
					</h4>

					<div className="grid gap-2 text-left">
						{bracketGroups.map((group) => (
							<div key={group.label}>
								{group.label === 'Non-holders' ? (
									<h4 className="mx-1 mb-1 flex items-center justify-between text-xs font-bold uppercase text-gray">
										{group.label} &#40;
										{nonHolderOrgs.length}
										&#41;
									</h4>
								) : (
									<h5 className="mx-1 mb-1 text-xs font-bold text-gray">
										{group.label}
									</h5>
								)}

								{group.orgs.map((org) => (
									<ManualOrg
										org={org}
										key={org.id}
										onHandleManualFirmSelection={
											onHandleManualFirmSelection
										}
									/>
								))}
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

export default DistributionManualSection
