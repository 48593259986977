import clsx from 'clsx'
import { VERSION } from 'core/utils/version'
import { ComponentProps } from 'react'
import { Link } from 'react-router-dom'
import AppVersion from '../AppVersion'

type Props = ComponentProps<'footer'>

/**
 * The application's footer
 *
 * @example
 * <Footer />
 */
function Footer({ className, ...props }: Props) {
	return (
		<footer
			{...props}
			className={clsx(
				className,
				'mt-auto flex h-8 flex-none items-center justify-end gap-1 px-7 py-2 text-2xs leading-relaxed text-gray-dark 2xl:px-16'
			)}
		>
			{!!VERSION && (
				<>
					<AppVersion />
					<span>|</span>
				</>
			)}
			<Link className="hover:text-white" to="/legal/privacy">
				Privacy Policy
			</Link>
			<span>|</span>
			<span>Copyright &copy; {new Date().getFullYear()} Appital</span>
		</footer>
	)
}

export default Footer
