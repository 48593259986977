import clsx from 'clsx'
import { ComponentProps } from 'react'

type Props = ComponentProps<'div'> & {
	size?: number
	color?: SpinnerColor
	delay?: number | boolean
	fadeIn?: boolean
}

export type SpinnerColor = (typeof colors)[number]

const defaultDelay = 333

export const colors = [
	'yellow',
	'magenta',
	'green',
	'gray',
	'gray-dark',
	'gray-light',
	'white',
	'blue-darkest',
	'blue-highlight',
] as const

/**
 * Spinners are used when retrieving data or performing slow computations, and
 * help to notify users that loading is underway.
 *
 * @example
 * <Spinner />
 * <Spinner size={16} />
 * <Spinner delay={666} />
 * <Spinner color="green" />
 */
function Spinner({
	size = 32,
	color = 'yellow',
	delay = defaultDelay,
	fadeIn = true,
	children,
	className,
	...props
}: Props) {
	return (
		<div
			role="alert"
			aria-live="polite"
			aria-label="Loading"
			{...props}
			className={clsx(
				'flex flex-1 items-center justify-center gap-3',
				fadeIn && 'animate-fade-in animate-duration-slower',
				color === 'yellow' && 'text-yellow',
				color === 'magenta' && 'text-magenta',
				color === 'green' && 'text-green',
				color === 'gray' && 'text-gray',
				color === 'gray-dark' && 'text-gray-dark',
				color === 'gray-light' && 'text-gray-light',
				color === 'white' && 'text-white',
				color === 'blue-darkest' && 'text-blue-darkest',
				color === 'blue-highlight' && 'text-blue-highlight',
				className
			)}
			style={{
				animationDelay:
					(typeof delay === 'number'
						? delay
						: !!delay
							? defaultDelay
							: 0) + 'ms',
			}}
		>
			<span
				className="relative block aspect-square"
				style={{ width: size }}
			>
				<span
					className="absolute inset-0 box-border rounded-full border-current opacity-10"
					style={{ borderWidth: size * 0.1 }}
				/>
				<span
					className="absolute inset-0 animate-spin rounded-full border-transparent border-t-current will-change-transform"
					style={{ borderWidth: size * 0.1 }}
				/>
			</span>

			{children && <span>{children}</span>}
		</div>
	)
}

export default Spinner
