import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

type Props = Omit<
	ComponentPropsWithoutRef<typeof motion.div>,
	'initial' | 'animate' | 'exit'
> & {
	isVisible: boolean
	initial?: boolean
	horizontal?: boolean
	children: ReactNode
}

const variants = {
	vertical: {
		closed: { height: 0, opacity: 0 },
		open: { height: 'auto', opacity: 1 },
	},
	horizontal: {
		closed: { width: 0, opacity: 0 },
		open: { width: 'auto', opacity: 1 },
	},
}

export default function AnimateVisibility({
	isVisible,
	initial,
	horizontal,
	children,
	className,
	...props
}: Props) {
	return (
		<AnimatePresence initial={initial}>
			{isVisible && (
				<motion.div
					{...props}
					variants={
						horizontal ? variants.horizontal : variants.vertical
					}
					initial="closed"
					animate="open"
					exit="closed"
					className={clsx('overflow-hidden', className)}
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	)
}
