import clsx from 'clsx'

type Params = {
	bgColor: 'blue-dark' | 'blue-darker'
	textColor: 'gray-dark' | 'white'
	hoverable?: boolean
	focusable?: boolean
	active?: boolean
	disabled?: boolean
}

export function distributionGradientBorder({
	bgColor,
	textColor,
	hoverable = false,
	focusable = false,
	active = false,
	disabled = false,
}: Params) {
	return clsx(
		// A bit of trickery here to get the gradient border to behave with border-radius
		// https://css-tricks.com/gradient-borders-in-css/
		'relative rounded-xl border-2 border-transparent bg-clip-padding transition-colors',

		// before (used for hover/focus)
		'before:absolute before:inset-0 before:-z-2 before:-m-0.5 before:rounded-[inherit] before:transition-all before:duration-fast before:content-[""]',

		// after (used for checked)
		'after:absolute after:inset-0 after:-z-1 after:-m-0.5 after:rounded-[inherit] after:bg-gradient-from-tl after:bg-gradient-to-br after:from-[#847BBE] after:to-yellow after:opacity-0 after:transition-opacity after:duration-normal after:content-[""]',
		bgColor === 'blue-dark' && 'bg-blue-dark before:bg-blue-dark',

		// color variations
		bgColor === 'blue-darker' && 'bg-blue-darker before:bg-blue-darker',
		textColor === 'gray-dark' && 'text-gray-dark',
		textColor === 'white' && 'text-white',

		// hover
		hoverable &&
			'hover:cursor-pointer hover:text-gray hover:before:bg-gray-dark hover:before:opacity-50',

		// focus
		focusable &&
			'focus-within:text-gray focus-within:before:bg-gray-dark focus-within:before:opacity-50',

		// active
		active && 'after:!opacity-100',

		active && bgColor === 'blue-dark' && 'hover:bg-blue-darkish',

		// disabled
		disabled && 'pointer-events-none opacity-50 after:-z-3'
	)
}
