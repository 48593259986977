import clsx from 'clsx'
import { ComponentProps, FC } from 'react'

type Props = ComponentProps<'svg'>

const TickCircleIcon: FC<Props> = ({ ...props }: Props) => (
	<svg
		{...props}
		fill="none"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		className={clsx(props.className)}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.8333 10L10.7917 15.0417L8.5 12.75"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default TickCircleIcon
