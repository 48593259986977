import clsx from 'clsx'
import { motion, Variants } from 'framer-motion'
import { ComponentProps, forwardRef } from 'react'

type Props = ComponentProps<typeof motion.ul> & {
	isOpen: boolean
}

const menuAnimationVariants: Variants = {
	open: { opacity: 1, scaleY: 1 },
	closed: { opacity: 0, scaleY: 0 },
}

const AutocompleteList = forwardRef<HTMLUListElement, Props>(
	function AutocompleteList({ isOpen, className, ...props }, ref) {
		return (
			<motion.ul
				{...props}
				ref={ref}
				animate={isOpen ? 'open' : 'closed'}
				initial="closed"
				exit="closed"
				variants={menuAnimationVariants}
				transition={{
					type: 'spring',
					stiffness: 800,
					damping: 40,
				}}
				className={clsx(
					'absolute left-0 top-full m-0 origin-[top_center] list-none overflow-y-auto rounded-xl rounded-t-none bg-blue-dark p-3 text-sm shadow-lg outline-none',
					className
				)}
			/>
		)
	}
)

export default AutocompleteList
