import ErrorMessage from 'core/components/ErrorMessage'
import Heading from 'core/components/Heading'
import { AlertCircle } from 'core/components/Icon'

const PageNotFound = () => (
	<ErrorMessage>
		<AlertCircle
			size={48}
			className="animate-fade-in-scale animate-duration-slow"
		/>

		<Heading as="h1" size={4} className="mt-5">
			Page Not Found
		</Heading>

		<p className="mt-3 max-w-lg text-sm">
			Unfortunately, this page does not exist.
			<br />
			If you think this is an error, please let us know at{' '}
			<a
				href="mailto:support@appital.io"
				className="font-bold hover:text-white focus-visible:text-white"
			>
				support@appital.io
			</a>
		</p>
	</ErrorMessage>
)

export default PageNotFound
