import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { SESSIONSTORAGE_TOKEN_ENABLED } from './config'

/**
 * AuthenticatedOnly ensures the given React children are only rendered if there
 * is a valid login session. It also ensures the user is logged out if idle for
 * too long.
 */
export const AuthenticatedOnly = ({ children }: { children: ReactNode }) => {
	const localToken = window.sessionStorage.getItem('token')

	if (localToken && SESSIONSTORAGE_TOKEN_ENABLED) return <>{children}</>

	return <AuthenticatedViaAuth0>{children}</AuthenticatedViaAuth0>
}

/**
 * AuthenticatedViaAuth0 uses the HOC `withAuthenticationRequired` to ensure
 * there's a valid Auth0-provided login session. If not, it redirects to the
 * Auth0 sign-in page.
 */
const AuthenticatedViaAuth0 = withAuthenticationRequired(
	({ children }: { children: ReactNode }) => <>{children}</>
)

export default AuthenticatedOnly
