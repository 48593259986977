import { init as initAnalytics, trackPageView } from 'analytics'
import { Distribution } from 'bookbuilds/components/DistributionForm'
import Spinner from 'core/components/Spinner'
import PageNotFound from 'core/pages/NotFound'
import { apiRequest } from 'core/utils/api'
import { sendErrorReport } from 'core/utils/errorReporter'
import { HoldingTrend, OrgCategory, Status } from 'graphql/generated'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DistributeWithTokenForm from './DistributeWithTokenForm'
import Invalid from './Invalid'
import Originated from './Originated'

type DistributionOrg = {
	id: string
	name: string
	category?: OrgCategory
}

type Holder = {
	instrumentID: string
	fuzzyHoldingAmount: string
	rawHoldingAmount: number
	trend: HoldingTrend
	reportDate?: string | null
	valueChangedUSD?: number
	valueChangedADVPercent?: number
	organization: { id: string; name: string }
}

type PreviewReach = {
	liveWatchlistsFirms: number
	passiveInterestFirms: number
	priceDiscoveryFirms: number
}

export type DataStatus = `${Status}` | 'loading' | ''

// Data is the payload received from the GET and PUT requests
export type Data = {
	bookbuildId: string
	userId: string
	sessionId: number
	status: `${Status}` | ''
	instrumentName: string
	marketName: string
	liveWatchlists: boolean
	passiveInterest: boolean
	priceDiscovery: boolean
	manualOrgIds?: string[] | null
	excludedOrgIds?: string[] | null
	distributionOrgs?: DistributionOrg[] | null
	previewReach: PreviewReach
	holders?: Holder[] | null
	primaryBloombergTicker: string
	endsAt: string
	onLiveWatchlistsChecked: (checked: boolean) => void
	onPassiveInterestChecked: (checked: boolean) => void
	onManualChecked: (checked: boolean) => void
	onManualOrgIdSelected: (id: string, selected: boolean) => void
}

// StartPayload is the data to be sent when submitting the bookbuild
// distribution parameters
type StartPayload = {
	liveWatchlists: boolean
	passiveInterest: boolean
	priceDiscovery: boolean
	manualOrgIds: string[]
	excludedOrgIds: string[]
}

export const API_PATH = '/distribute'

const DistributeWithToken = () => {
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const token = query.get('token')
	const [dataStatus, setDataStatus] = useState<DataStatus>('loading')
	const [data, setData] = useState<Data | undefined>()

	const fetchData = useCallback(
		(abortController: AbortController) => {
			if (!token) return

			apiRequest({ path: API_PATH, token, abortController })
				.then(async (response) => {
					const d: Data = await response.json()

					if (
						!d ||
						!d.hasOwnProperty('liveWatchlists') ||
						!d.hasOwnProperty('passiveInterest')
					) {
						throw new Error('')
					}

					setData((currentData) => {
						const noDataYet = !currentData
						const hasPotentialPriceDiscoveryFirms =
							d.previewReach.priceDiscoveryFirms > 0

						// Price discovery is enabled by default if there are
						// potential firms to target
						if (noDataYet && hasPotentialPriceDiscoveryFirms) {
							return { ...d, priceDiscovery: true }
						}

						return d
					})
					setDataStatus(d.status)

					// Initialise analytics as the originator user
					initAnalytics({
						userId: d.userId,
						sessionId: d.sessionId,
						getToken: async () => token,
					})

					// Track the pageview
					trackPageView()
				})
				.catch((err) => {
					if (err instanceof Error) {
						setDataStatus(err.message as DataStatus)
					}
					sendErrorReport(err as Error)
				})
		},
		[token]
	)

	// fetch the required data to render the UI
	useEffect(() => {
		const abortController = new AbortController()
		fetchData(abortController)
		return () => abortController.abort()
	}, [fetchData])

	// poll for fresh data as the preview reach may change
	useEffect(() => {
		if (dataStatus !== 'draft') return
		const abortController = new AbortController()
		const i = setInterval(fetchData, 3000)
		return () => {
			abortController.abort()
			clearInterval(i)
		}
	}, [dataStatus, fetchData])

	// handleStart makes a PUT request with the form data to start the bookbuild
	const handleStart = useCallback(
		async (distribution: Distribution) => {
			if (!token) return

			const submit: StartPayload = {
				liveWatchlists: distribution.includeLiveWatchlists,
				passiveInterest: distribution.includePassiveInterest,
				priceDiscovery: distribution.includePriceDiscovery,
				manualOrgIds: distribution.orgIds.filter(
					(id) => !distribution.excludedOrgIds.includes(id)
				),
				excludedOrgIds: distribution.excludedOrgIds,
			}

			try {
				await apiRequest({
					path: API_PATH,
					token,
					method: 'PUT',
					payload: submit,
				})
			} catch (err) {
				sendErrorReport(err)
				// Mask this error
				throw new Error('')
			}
		},
		[token]
	)

	// handleCancel makes a DELETE request to cancel the bookbuild
	const handleCancel = useCallback(async () => {
		if (!token) return

		try {
			await apiRequest({ path: API_PATH, token, method: 'DELETE' })
		} catch (err) {
			sendErrorReport(err)
			// Mask this error
			throw new Error('')
		}
	}, [token])

	if (!token) {
		return <PageNotFound />
	}

	switch (dataStatus) {
		case 'loading':
			return <Spinner />

		case 'draft':
			if (!data) {
				return <Invalid />
			}
			return (
				<DistributeWithTokenForm
					data={data}
					onStart={handleStart}
					onCancel={handleCancel}
				/>
			)

		case 'live':
		case 'allocated':
		case 'executing':
		case 'completed':
		case 'suspended':
			return <Originated />

		case 'cancelled':
		case 'expired':
		case 'failed':
			return <Invalid status={dataStatus} />

		default:
			return <Invalid />
	}
}

export default DistributeWithToken
