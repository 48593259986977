import clsx from 'clsx'
import { ComponentProps, FC } from 'react'

type Props = ComponentProps<'svg'>

const ExcludeIcon: FC<Props> = ({ ...props }: Props) => (
	<svg
		{...props}
		fill="none"
		viewBox="0 0 22 22"
		xmlns="http://www.w3.org/2000/svg"
		className={clsx(props.className)}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.17933 14H2L2 11.3509L6.17893 7.17196C6.06401 7.42428 6 7.70466 6 8V10.1793L2.17933 14ZM8 6C7.70466 6 7.42428 6.06401 7.17196 6.17893L11.3509 2L14 2V2.17931L10.1793 6H8ZM8 8.17932V8H8.17932H9.59353H9.92122H11.3354H12.422H13.8362H14V8.16376L14 9.57797V10.6644V12.0787V12.4065V13.8207V14H13.8207H12.4065H12.0788H10.6646H9.57798L8.16376 14H8V13.8362V12.422V11.3356V9.92133V9.59353V8.17932ZM3.59354 14L6 11.5935V14H3.59354ZM2 16H6V20C6 21.1046 6.89543 22 8 22H20C21.1046 22 22 21.1046 22 20V8C22 6.89543 21.1046 6 20 6H16V2C16 0.895431 15.1046 0 14 0H2C0.895432 0 0 0.895429 0 2V14C0 15.1046 0.895431 16 2 16ZM18.4065 8H16V10.4065L18.4065 8ZM8 18.4065L10.4065 16H8V18.4065ZM14 16H11.8207L8 19.8207V20H10.6491L14.828 15.8211C14.5757 15.936 14.2953 16 14 16ZM16 14C16 14.2953 15.936 14.5757 15.8211 14.828L20 10.6491V8H19.8207L16 11.8207V14ZM14 3.59352V6H11.5935L14 3.59352ZM9.93667 2L7.10825 2L2 7.10826L2 9.93668L9.93667 2ZM2 5.69404L5.69403 2L2 2L2 5.69404ZM12.0633 20H14.8918L20 14.8917V12.0633L12.0633 20ZM20 16.306L16.306 20H20V16.306Z"
			fill="#9498BF"
		/>
	</svg>
)

export default ExcludeIcon
