import clsx from 'clsx'
import Badge from 'core/components/Badge'
import { Activity } from 'core/components/Icon'
import Tooltip from 'core/components/Tooltip'
import { abbreviateNumber } from 'core/utils/number'
import { HoldingTrend } from 'graphql/generated'
import { ComponentPropsWithoutRef } from 'react'

type Props = Omit<ComponentPropsWithoutRef<typeof Badge>, 'children'> & {
	notionalChangedUSD?: number
	trend?: HoldingTrend
	daysSinceLastReport: number
}

const RecentChangeBadge = ({
	notionalChangedUSD,
	trend,
	daysSinceLastReport,
	className,
}: Props) => {
	if (!notionalChangedUSD || !trend) return null

	return (
		<Tooltip
			className="shadow-[3px_0px_20px_0px_rgba(0,0,0,0.45)]"
			content={
				<span>
					<span
						className={clsx(
							'mr-2 text-sm font-bold tracking-[0.25px]',
							trend === HoldingTrend.Down && 'text-magenta',
							trend === HoldingTrend.Up && 'text-green'
						)}
					>
						{trend === HoldingTrend.Down && '-$'}
						{trend === HoldingTrend.Up && '+$'}
						{abbreviateNumber(notionalChangedUSD)}
					</span>
					<span className="text-sm font-normal text-gray-light">
						{daysSinceLastReport} days ago
					</span>
				</span>
			}
		>
			<Badge
				className={clsx(
					'mx-2.5 whitespace-nowrap bg-blue-highlight bg-opacity-70 group-hover:bg-opacity-80 group-active:bg-opacity-80',
					className
				)}
			>
				<span className="flex items-center gap-[3px]">
					<Activity size={12} />
					<span className="hidden text-3xs md:flex lg:hidden min-[1150px]:flex">
						Recent Change
					</span>
				</span>
			</Badge>
		</Tooltip>
	)
}

export default RecentChangeBadge
