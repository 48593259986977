import clsx from 'clsx'
import { motion } from 'framer-motion'
import { ComponentProps, forwardRef } from 'react'

type Props = ComponentProps<typeof motion.li>

const AutocompleteListItem = forwardRef<HTMLLIElement, Props>(
	function AutocompleteListItem({ className, ...props }, ref) {
		return (
			<motion.li
				{...props}
				ref={ref}
				className={clsx(
					'm-0 cursor-pointer p-0 text-white aria-selected:text-yellow',
					className
				)}
			/>
		)
	}
)

export default AutocompleteListItem
