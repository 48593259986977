import { events } from 'analytics'
import clsx from 'clsx'
import Button from 'core/components/Button'
import ButtonAction from 'core/components/ButtonAction'
import { FormErrorAnimated } from 'core/components/FormError'
import { ChevronDown } from 'core/components/Icon'
import SideLabel from 'core/components/SideLabel'
import { errorToString } from 'core/utils/error'
import { sendErrorReport } from 'core/utils/errorReporter'
import { getTimeDifference } from 'core/utils/time'
import { ActionStatus } from 'core/utils/types'
import { parseISO } from 'date-fns'
import { HoldingTrend, OrgCategory, Side } from 'graphql/generated'
import uniq from 'lodash.uniq'
import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from 'react'
import BookbuildDetails from './BookbuildDetails'
import DistributionEndTime from './EndTime'
import DistributionInterestSection from './InterestSection'
import DistributionManualSection from './ManualSection'

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	bookbuildId: string
	bookbuildEndsAt: string
	instrumentDescription: string
	instrumentMarketName: string
	instrumentTicker: string
	bookbuildDetails?: DistributionBookbuildDetails
	initial: Distribution
	distributionOrgs: DistributionOrg[]
	holders: DistributionHolder[]
	previewReach: DistributionPreviewReach
	disabled?: boolean
	onStart: (distribution: Distribution) => Promise<void>
	onCancel: () => Promise<void>
}

export type DistributionBookbuildDetails = {
	side: Side
	targetVolume: number
	adtv: string
	targetPrice: number
	currency: string
	upsizeVolume?: number | null
	upsizeAdtv?: string | null
	upsizePrice?: number | null
	brokerName: string
}

export type Distribution = {
	includeLiveWatchlists: boolean
	includePassiveInterest: boolean
	includePriceDiscovery: boolean
	orgIds: string[]
	excludedOrgIds: string[]
}

export type DistributionOrg = {
	id: string
	name: string
	category?: OrgCategory | null
}

type DistributionHolder = {
	fuzzyHoldingAmount: string
	trend: HoldingTrend
	reportDate?: string | null
	valueChangedUSD?: number
	valueChangedADVPercent?: number
	organization: {
		id: string
		name: string
	}
}

export type SelectableOrg = {
	id: string
	name: string
	fuzzyHoldingAmount?: string
	trend?: HoldingTrend
	category?: OrgCategory | null
	reportDate?: string | null
	valueChangedUSD?: number
	valueChangedADVPercent?: number
}

export type DistributionPreviewReach = {
	liveWatchlistsFirms: number
	passiveInterestFirms: number
	priceDiscoveryFirms: number
}

export function getDistributionSelectableOrgs(
	distributionOrgs: DistributionOrg[],
	holders: DistributionHolder[]
): SelectableOrg[] {
	const holderOrgIds = holders.map((h) => h.organization.id)

	return (
		distributionOrgs
			.map((org) => {
				const holding = holders.find(
					(holder) => holder.organization.id === org.id
				)

				const selectableOrg: SelectableOrg = {
					id: org.id,
					name: org.name,
					fuzzyHoldingAmount: holding?.fuzzyHoldingAmount,
					trend: holding?.trend,
					category: org.category,
					reportDate: holding?.reportDate,
					valueChangedADVPercent: holding?.valueChangedADVPercent,
					valueChangedUSD: holding?.valueChangedUSD,
				}

				return selectableOrg
			})
			// sort by the order of the holders which is sorted by raw holding amount
			.sort(
				(a, b) =>
					holderOrgIds.indexOf(a.id) - holderOrgIds.indexOf(b.id)
			)
	)
}

export default function DistributionForm({
	bookbuildId,
	bookbuildEndsAt,
	instrumentDescription,
	instrumentMarketName,
	instrumentTicker,
	bookbuildDetails,
	initial,
	distributionOrgs,
	holders,
	previewReach,
	disabled = false,
	onStart,
	onCancel,
	...props
}: Props) {
	const [distribution, setDistribution] = useState<Distribution>(initial)
	const [errorMessage, setErrorMessage] = useState('')
	const [startStatus, setStartStatus] = useState<ActionStatus>('initial')
	const [cancelStatus, setCancelStatus] = useState<ActionStatus>('initial')

	const selectableOrgs: SelectableOrg[] = useMemo(
		() => getDistributionSelectableOrgs(distributionOrgs, holders),
		[distributionOrgs, holders]
	)

	const [isDetailsOpen, setDetailsOpen] = useState(false)

	const toggleDetailsOpen = useCallback(() => setDetailsOpen((x) => !x), [])

	const handleOrgSelected = useCallback((id: string, selected: boolean) => {
		setDistribution((distribution) => ({
			...distribution,
			orgIds: selected
				? uniq([...distribution.orgIds, id])
				: distribution.orgIds.filter((orgId) => orgId !== id),
		}))
	}, [])

	const handleOrgsChange = useCallback((ids: string[]) => {
		setDistribution((distribution) => ({
			...distribution,
			orgIds: ids,
		}))
	}, [])

	const handleClearOrgs = useCallback(
		() =>
			setDistribution((distribution) => ({
				...distribution,
				orgIds: [],
			})),
		[]
	)

	const handlePriceDiscoveryChecked = useCallback((checked: boolean) => {
		setDistribution((distribution) => ({
			...distribution,
			includePriceDiscovery: checked,
		}))
	}, [])

	const handleLiveWatchlistsChecked = useCallback(
		(checked: boolean) => {
			setDistribution((distribution) => ({
				...distribution,
				includeLiveWatchlists: checked,
			}))

			if (checked) {
				events.distributionLiveWatchlistsIncluded({
					'BookBuild ID': bookbuildId,
				})
			}
		},
		[bookbuildId]
	)

	const handlePassiveInterestChecked = useCallback(
		(checked: boolean) => {
			setDistribution((distribution) => ({
				...distribution,
				includePassiveInterest: checked,
			}))

			if (checked) {
				events.distributionPassiveWatchlistsIncluded({
					'BookBuild ID': bookbuildId,
				})
			}
		},
		[bookbuildId]
	)

	const handleExclusionsChange = useCallback(
		(ids: string[]) =>
			setDistribution((distribution) => ({
				...distribution,
				excludedOrgIds: ids,
			})),
		[]
	)

	// work out how many firms are targeted so we can disable the submit button
	const liveWatchlistFirmsTargeted = distribution.includeLiveWatchlists
		? previewReach.liveWatchlistsFirms
		: 0

	const passiveInterestFirmsTargeted = distribution.includePassiveInterest
		? previewReach.passiveInterestFirms
		: 0

	const priceDiscoveryFirmsTargeted = distribution.includePriceDiscovery
		? previewReach.priceDiscoveryFirms
		: 0

	const manualFirmsTargeted = distribution.orgIds.length

	const totalFirmsTargeted =
		liveWatchlistFirmsTargeted +
		passiveInterestFirmsTargeted +
		priceDiscoveryFirmsTargeted +
		manualFirmsTargeted

	const duration = getTimeDifference(parseISO(bookbuildEndsAt), new Date())

	const isCompleted =
		startStatus === 'completed' || cancelStatus === 'completed'

	// Data needed for tracking events
	const selectedOrgIds = useMemo(
		() =>
			distribution.orgIds.filter(
				(id) => !distribution.excludedOrgIds.includes(id)
			),
		[distribution.excludedOrgIds, distribution.orgIds]
	)

	const holderOrgIds = useMemo(
		() => holders.map((holder) => holder.organization.id),
		[holders]
	)

	const selectedHolderOrgIds = useMemo(
		() => selectedOrgIds.filter((id) => holderOrgIds.includes(id)),
		[holderOrgIds, selectedOrgIds]
	)

	const handleBookbuildStartCancelTracking = useCallback(
		(trigger: string) => {
			const data = {
				'BookBuild ID': bookbuildId,
				'Total Firms Excluded': distribution.excludedOrgIds.length,
				'Total Firms Selected': selectedOrgIds.length,
				'Total Firms Unselected':
					distributionOrgs.length - selectedOrgIds.length,
				'Total Holders Selected': selectedHolderOrgIds.length,
				'Total Holders Unselected':
					holderOrgIds.length - selectedHolderOrgIds.length,
				'Total Non-Holders Selected':
					selectedOrgIds.length - selectedHolderOrgIds.length,
				'Total Live Watchlist Firms Included':
					liveWatchlistFirmsTargeted,
				'Total Passive Watchlist Firms Included':
					passiveInterestFirmsTargeted,
			}

			if (trigger === 'start') {
				events.bookBuildStartClicked(data)
			} else {
				events.bookBuildCancelClicked(data)
			}
		},
		[
			bookbuildId,
			distribution.excludedOrgIds.length,
			distributionOrgs.length,
			holderOrgIds.length,
			liveWatchlistFirmsTargeted,
			passiveInterestFirmsTargeted,
			selectedHolderOrgIds.length,
			selectedOrgIds.length,
		]
	)

	const handleStart = useCallback(async () => {
		try {
			setErrorMessage('')
			setStartStatus('loading')
			await onStart(distribution)
			setStartStatus('completed')
			handleBookbuildStartCancelTracking('start')
		} catch (err) {
			setErrorMessage(errorToString(err))
			setStartStatus('failed')
			setTimeout(() => setStartStatus('initial'), 1000)
			sendErrorReport(err)
		}
	}, [distribution, handleBookbuildStartCancelTracking, onStart])

	const handleCancel = useCallback(async () => {
		try {
			setErrorMessage('')
			setCancelStatus('loading')
			await onCancel()
			handleBookbuildStartCancelTracking('cancel')
			setCancelStatus('completed')
		} catch (err) {
			setErrorMessage(errorToString(err))
			setCancelStatus('failed')
			setTimeout(() => setCancelStatus('initial'), 1000)
			sendErrorReport(err)
		}
	}, [handleBookbuildStartCancelTracking, onCancel])

	return (
		<div {...props}>
			<div className="rounded-xl bg-blue-dark px-8 py-6 xl:py-8">
				<header className="mb-8 flex flex-col gap-4 md:flex-row md:items-start md:justify-between md:gap-6">
					<div className="text-xs font-bold uppercase">
						<div className="mb-2 flex items-center gap-4">
							{!!bookbuildDetails && (
								<SideLabel side={bookbuildDetails.side} />
							)}
							<h2 className="whitespace-nowrap text-lg leading-none text-white">
								{instrumentDescription}
							</h2>
						</div>

						<div className="flex flex-nowrap items-center gap-2">
							<p className="whitespace-nowrap text-3xs text-gray-light">
								{instrumentTicker}
							</p>

							<p
								className={clsx(
									"whitespace-nowrap text-3xs text-gray before:mr-2 before:opacity-30 before:content-['|']",
									!!bookbuildDetails &&
										"after:ml-2 after:opacity-30 after:content-['|']"
								)}
							>
								{instrumentMarketName}
							</p>

							{!!bookbuildDetails && (
								<Button
									variant="bare"
									color="gray"
									size="tiny"
									className="!gap-1 font-bold !leading-[19px]"
									onClick={toggleDetailsOpen}
								>
									BookBuild Details
									<ChevronDown
										size={16}
										className={clsx(
											'mt-0.5 transition-transform duration-normal',
											isDetailsOpen
												? 'rotate-180'
												: 'rotate-0'
										)}
									/>
								</Button>
							)}
						</div>
					</div>

					<DistributionEndTime
						duration={duration}
						expirationTime={bookbuildEndsAt}
					/>
				</header>

				{!!bookbuildDetails && (
					<BookbuildDetails
						isOpen={isDetailsOpen}
						targetVolume={bookbuildDetails.targetVolume}
						adtv={
							typeof bookbuildDetails.adtv === 'string'
								? parseInt(bookbuildDetails.adtv)
								: bookbuildDetails.adtv
						}
						targetPrice={bookbuildDetails.targetPrice}
						currency={bookbuildDetails.currency}
						upsizeVolume={bookbuildDetails.upsizeVolume}
						upsizeAdtv={
							typeof bookbuildDetails.upsizeAdtv === 'string'
								? parseInt(bookbuildDetails.upsizeAdtv)
								: bookbuildDetails.upsizeAdtv
						}
						upsizePrice={bookbuildDetails.upsizePrice}
						brokerName={bookbuildDetails.brokerName}
					/>
				)}

				<div
					{...props}
					className={clsx(
						'relative z-0 grid grid-cols-1 gap-6 lg:grid-cols-2',
						(disabled || isCompleted) && 'pointer-events-none'
					)}
				>
					<DistributionManualSection
						trackingBookbuildId={bookbuildId}
						selectableOrgs={selectableOrgs}
						selectedOrgIds={distribution.orgIds}
						excludedOrgIds={distribution.excludedOrgIds}
						onSelected={handleOrgSelected}
						onMultipleSelected={handleOrgsChange}
						onSelectNone={handleClearOrgs}
					/>

					<DistributionInterestSection
						bookbuildId={bookbuildId}
						reach={previewReach}
						includeLiveWatchlists={
							distribution.includeLiveWatchlists
						}
						includePassiveInterest={
							distribution.includePassiveInterest
						}
						includePriceDiscovery={
							distribution.includePriceDiscovery
						}
						excludedOrgIds={distribution.excludedOrgIds}
						selectableOrgs={selectableOrgs}
						onLiveWatchlistsChecked={handleLiveWatchlistsChecked}
						onPassiveInterestChecked={handlePassiveInterestChecked}
						onPriceDiscoveryChecked={handlePriceDiscoveryChecked}
						onExcludedOrgIdsChanged={handleExclusionsChange}
					/>
				</div>

				<FormErrorAnimated isVisible={!!errorMessage}>
					{errorMessage}
				</FormErrorAnimated>
			</div>

			<div className="mt-6 flex flex-wrap items-center justify-between gap-x-5 gap-y-3">
				<ButtonAction
					status={startStatus}
					size="large"
					disabled={
						totalFirmsTargeted === 0 ||
						cancelStatus === 'loading' ||
						cancelStatus === 'completed'
					}
					label="Start BookBuild"
					loadingLabel="Starting"
					completedLabel="BookBuild Started"
					failedLabel="Failed to Start"
					onClick={handleStart}
				/>

				<ButtonAction
					status={cancelStatus}
					color="gray-dark"
					label="Cancel BookBuild"
					variant="bare"
					loadingLabel="Cancelling"
					completedLabel="BookBuild Cancelled"
					failedLabel="Failed to Cancel"
					before={null}
					disabled={
						startStatus === 'loading' || startStatus === 'completed'
					}
					onClick={handleCancel}
				/>
			</div>
		</div>
	)
}
